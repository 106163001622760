import React, { FC, ReactNode } from 'react';
import { Grid, GridCol, GridRow } from '@legalshield/adonis-ux-framework';
import cn from 'classnames';

import AccountCard from '../buttons/AccountCard/AccountCard';
import { NavButton } from '../NavigationBar/NavigationBar';

import './CardGrid.scss';

export const renderAccountCardButtons = (cards: NavButton[]) =>
  cards.map((card, index) => (
    <GridCol key={index}>
      <AccountCard
        name={card.name}
        iconName={card.iconName}
        to={card.data}
        target={card.target}
        text={card.text}
        data={card.data}
      />
    </GridCol>
  ));

interface CardGridProps {
  children?: ReactNode | ReactNode[];
  classNames?: cn.Argument;
}

const CardGrid: FC<CardGridProps> = ({ children, classNames }) => (
  <Grid classNames={cn('basic-grid', classNames)}>
    <GridRow variant="halves" style={{ display: 'flex', justifyContent: 'left', marginTop: '4px' }}>
      {children as React.ReactElement}
    </GridRow>
  </Grid>
);

export default CardGrid;
