import React, { useMemo, useState } from 'react';
import { useHandleUpdatePaymentMethod } from './hooks/useUpdatePaymentMethod';
import { useNavigate } from 'react-router-dom';
import { PayType } from '@legalshield/frontend-commons/dist/sdk/groups';
import { Badge, Button, Dropdown, Icon, LinkContentProps, Tooltip } from '@legalshield/adonis-ux-framework';
import {
  SubscriptionStatus,
  UpdatePaymentMethod,
  FriendlyIdType,
} from '@legalshield/frontend-commons/dist/sdk/subscriptions';

import { ISubscriptionResource } from './hooks/utils/subscription-utils';
import { formatCardType, isBankAccount } from './utils/utils';
import { populateTemplateString } from '../../utils/stringFormat';
import { useGetGroupByGroupId } from './hooks/useGetGroup';
import { useGetPaymentMethods } from './hooks/usePaymentMethods';
import { usePrecancelModal } from './PrecancelModal/PrecancelModalContext';
import { useGetOutstandingInvoices } from './hooks/useGetOutstandingInvoices';
import './PaymentDropdown.scss';

import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import 'dayjs/locale/es';
import 'dayjs/locale/fr-ca';
import { PaymentMethodResource } from '@legalshield/frontend-commons/dist/sdk/payment-methods';
import { useGetPrecancelStatuses } from './hooks/useGetPrecancelStatuses';
import { UseMutationResult } from '@tanstack/react-query';

dayjs.extend(relativeTime);

type PaymentDropdownProps = {
  subscription: ISubscriptionResource;
  switchPaymentDuringUpgrade?: boolean;
};

interface PaymentOptionLinkProps extends LinkContentProps {
  isPaymentMethod: boolean;
  paymentMethod?: PaymentMethodResource;
}

const PaymentDropdown = ({ subscription, switchPaymentDuringUpgrade }: PaymentDropdownProps) => {
  const NO_LONGER_EMPLOYED = 'G';
  const { data: paymentMethods, isLoading: isLoadingPaymentMethods } = useGetPaymentMethods();
  const { id: subscriptionId } = subscription;
  const {
    mutateAsync: updatePaymentMethod,
    isError,
    isPending: updatePaymentInProgress,
  }: UseMutationResult<void, Error, UpdatePaymentMethod, unknown> = useHandleUpdatePaymentMethod(subscriptionId);
  const [currentPaymentMethodId, setCurrentPaymentMethodId] = useState<string | null>(subscription.paymentMethodId);
  const { data: invoicesData, isLoading: invoicesLoading } = useGetOutstandingInvoices(subscription?.id);
  const precancelStatus = useGetPrecancelStatuses(subscription?.source?.friendlyId);

  const { data: groupData } = useGetGroupByGroupId(subscription?.source?.groupId);
  const isGroupMemberPendingCancel = precancelStatus?.data?.some(
    (status) => status?.pendingCancelFlag === NO_LONGER_EMPLOYED,
  );
  const isSelfPay = groupData?.payType === PayType.SELF_PAY || !subscription?.source?.groupId;
  const isAssociate = subscription?.source.friendlyIdType === FriendlyIdType.ASSOCIATE;
  const isPrecancel =
    subscription?.status === SubscriptionStatus.PRECANCEL || invoicesData?.outstandingInvoices?.isPrecancel;
  const inPrecancelBiller = invoicesData?.outstandingInvoices?.inPrecancelBiller;

  const locale = pplsi?.market?.toLowerCase() || 'en-us';
  const normalizedLocale = locale.startsWith('en') ? 'en' : locale.startsWith('es') ? 'es' : locale;
  dayjs.locale(normalizedLocale);

  const { dispatch } = usePrecancelModal();
  const navigate = useNavigate();

  const addPaymentOption: PaymentOptionLinkProps = {
    icon: 'action_add',
    onClick: () => {
      navigate({
        pathname: '/payments/wallet',
        search: `?add_payment=Y&subscriptionId=${subscription?.id}&callback_url=${
          window.location.origin + `/payments/subscriptions`
        }&member=${subscription.source.friendlyId}`,
      });
    },
    role: 'option',
    text: string_table.ADD_PAYMENT_METHOD,
    isPaymentMethod: false,
  };

  const managePaymentOption: PaymentOptionLinkProps = {
    icon: 'edit_edit_pencil_01',
    onClick: () => {
      navigate({
        pathname: '/payments/wallet',
      });
    },
    role: 'option',
    text: string_table.MANAGE_PAYMENT_METHODS,
    isPaymentMethod: false,
  };

  const handlePaymentMethodClick = async (
    paymentMethodId: string,
    isPrecancel: boolean,
    inPrecancelBiller: boolean,
  ) => {
    if (isPrecancel && !inPrecancelBiller) {
      dispatch({
        payload: {
          paymentMethodId: paymentMethodId,
          subscription,
        },
        type: 'SHOW_MODAL',
      });
    } else {
      const updatedPaymentMethod: UpdatePaymentMethod = {
        paymentMethodId: paymentMethodId,
      };

      try {
        await updatePaymentMethod(updatedPaymentMethod);
        setCurrentPaymentMethodId(paymentMethodId);
      } catch (error) {
        console.error(`Error updating payment method in handlePaymentMethodClick: ${error}`);
      }
    }
  };

  const paymentOptionsList = useMemo(() => {
    if (!paymentMethods) {
      return [];
    }

    const formatWithSlash = (date?: string) => {
      if (!date || date.length !== 4) return '';

      return date.slice(0, 2) + '/' + date.slice(2);
    };

    return paymentMethods
      ?.map((paymentMethod) => {
        const creditCardNumber = paymentMethod?.creditCard?.cardNumber;
        const bankAccountType = () => {
          if (!paymentMethod?.bankAccount) return;
          return paymentMethod?.bankAccount?.bankAccountType === 'CHECKING'
            ? string_table.BANK_ACCOUNT_TYPE_CHECKING
            : string_table.BANK_ACCOUNT_TYPE_SAVINGS;
        };
        const paymentSelectionText = isBankAccount(paymentMethod)
          ? `${bankAccountType()} - ${paymentMethod?.bankAccount?.accountNumber?.slice(-4)}`
          : `${formatCardType(paymentMethod?.creditCard?.cardType)} - ${creditCardNumber?.slice(
              -4,
            )} ${populateTemplateString(string_table.SUBSCRIPTION_EXP_DATE, {
              Exp: formatWithSlash(paymentMethod?.creditCard?.expirationDate),
            })}`;

        const linkContent: PaymentOptionLinkProps = {
          id: paymentMethod?.id,
          key: paymentMethod?.id,
          onClick: () => {
            if (!isError) {
              handlePaymentMethodClick(paymentMethod?.id, isPrecancel, inPrecancelBiller);
            }
          },
          role: 'option',
          text: paymentSelectionText,
          isPaymentMethod: true,
          paymentMethod: paymentMethod,
        };
        return linkContent;
      })
      .concat(switchPaymentDuringUpgrade ? [] : [addPaymentOption, managePaymentOption]);
  }, [paymentMethods, isError, subscription, isPrecancel, inPrecancelBiller]);

  const defaultPaymentLabel = useMemo(() => {
    const selectedPaymentMethod = paymentMethods?.find((paymentMethod) => paymentMethod?.id === currentPaymentMethodId);
    if (selectedPaymentMethod) {
      const selectedPaymentOption = paymentOptionsList?.find(
        (paymentOption) => paymentOption.id === selectedPaymentMethod.id,
      );
      return selectedPaymentOption?.text;
    }
    return addPaymentOption.text;
  }, [paymentMethods, paymentOptionsList, currentPaymentMethodId]);

  return (
    <>
      <Dropdown.Root>
        <Dropdown.Trigger className="mr-4" data-testid="payment-dropdown">
          {isLoadingPaymentMethods || updatePaymentInProgress ? (
            <Button variant="secondary" label="Loading . . ." disabled />
          ) : (
            <Button
              variant="secondary"
              label={defaultPaymentLabel || 'Add a Payment Method'}
              iconRight="nav_chevron_single_down"
            />
          )}
        </Dropdown.Trigger>
        <Dropdown.Portal>
          {paymentOptionsList.map((option, index) => {
            const timeFromPaymentMethodCreation = dayjs(option?.paymentMethod?.createdAt).fromNow();
            const isPaymentMethod = option?.isPaymentMethod;
            const isActive = isPaymentMethod && option?.paymentMethod?.id === currentPaymentMethodId;
            return (
              <Dropdown.Item
                key={`payment-option-${index}`}
                onClick={option.onClick}
                role={option.role}
                data-testid={option.text}
                classNames={[`payment-dropdown-item ${isActive ? 'active' : ''}`]}
              >
                {option.text}
                {option.isPaymentMethod && (
                  <div data-testid="payment-created-at" className="right-slot">
                    <Tooltip
                      animation="scale"
                      placement="bottom"
                      text={`${string_table.CREATED} ${timeFromPaymentMethodCreation}`}
                    >
                      <Icon size="medium-small" name="calendar_clock" />
                    </Tooltip>
                  </div>
                )}
              </Dropdown.Item>
            );
          })}
        </Dropdown.Portal>
      </Dropdown.Root>
      {isPrecancel && !invoicesLoading && !precancelStatus?.isLoading && (isSelfPay || isAssociate) ? (
        <Badge
          text={
            isGroupMemberPendingCancel
              ? string_table.PRODUCTS_PAYMENT_FAILED_GROUP_PENDINGCANCEL
              : string_table.PRODUCTS_PAYMENT_FAILED
          }
          bold={true}
          variant="important"
        />
      ) : null}
    </>
  );
};

export default PaymentDropdown;
