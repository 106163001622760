export function getQuestionnaireNameByLocale(locale: string, questionnaireName: string) {
  const region = locale.toLowerCase().split('-')[0];

  switch (region) {
    case 'es':
    case 'fr':
      return `${questionnaireName}-${region}`;
    default:
      return questionnaireName;
  } 
}