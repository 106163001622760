import React, { FC } from 'react';
import { Container, Icon, Image, Text, Title } from '@legalshield/adonis-ux-framework';

import CardButton from '../CardButton/CardButton';
import { NavButton } from '../../NavigationBar/NavigationBar';

interface AccountCardProps extends NavButton {
  className?: string;
}

const AccountCard: FC<AccountCardProps> = ({ name, iconName, to, imageAlt, imageSrc, target, text }) => (
  <CardButton to={to} showArrow={true} iconName={iconName} heading={name} target={target} text={text}>
    <Container flexbox flexDirection="column" classNames={['p-5']}>
      {imageSrc ? (
        <Image
          src={imageSrc}
          alt={imageAlt}
          style={{
            width: '32px',
            height: '32px',
          }}
        />
      ) : (
        <Icon name={iconName} size="xlarge" color="N800" />
      )}
      <Title textSize={'large'} text={name} classNames={'pt-4'} />
      <Text textSize="large" text={text} classNames={['pt-3', 'card-button__text']} />
    </Container>
  </CardButton>
);

export default AccountCard;
