import { useMutation } from '@tanstack/react-query';
import { Answers, SDKHeaders } from '@legalshield/frontend-commons';

import { useBannerAlert } from '../../Alerts/Banner/context/BannerAlertContext';
import { getQuestionnaireNameByLocale } from './getQuestionnaireNameByLocale';

const answersApi = new Answers.Api();

const identityId = pplsi?.authNPayload?.sub;
const locale = pplsi?.market ?? 'en-US';

function validateStatus(status: number) {
  return status >= 200 && status < 300;
}

const params = {
  ...SDKHeaders.getVersionConfig(0), validateStatus
}

type SubmitFeedbackInput = {
  questionnaireName: string;
  freeformText: string;
  likelyToComeBack: string;
  contactByPhone: boolean;
  contactByEmail: boolean;
};

export function useSubmitFeedback() {
  const { bannerAlertDispatch } = useBannerAlert();
  return useMutation({
    mutationFn: async (submitFeedbackInput: SubmitFeedbackInput) => {
      const { likelyToComeBack, freeformText, contactByPhone, contactByEmail, questionnaireName } = submitFeedbackInput;
      if (!likelyToComeBack && !freeformText && !questionnaireName) {
        throw new Error('Required fields must be provided');
      }

      const answers = constructAnswerObject(freeformText, likelyToComeBack, contactByPhone, contactByEmail, questionnaireName);

      const { data: createdAnswer } = await answersApi.v1.answersCreateAnswer(identityId, answers as Answers.Answer, params);

      const { data: submittedAnswer } = await answersApi.v1.answersSubmitAnswer(identityId, createdAnswer.id, params);

      return submittedAnswer;
    },
    onError: (error: Error) => {
      console.error(`Error submitting feedback: ${error}`);
      bannerAlertDispatch({ message: string_table.ALERT_ERROR, severity: 'error', type: 'DISPLAY' });
    },
  });
}

function constructAnswerObject(freeformText: string, likelyToComeBack: string, contactByPhone: boolean, contactByEmail: boolean, questionnaireName: string) {
  const answers = {
    questionnaireName: getQuestionnaireNameByLocale(locale, questionnaireName),    
    tasks: { 
      task1: {
        textArea: freeformText
      },
      task2: {
        radio1: likelyToComeBack
      },
      task3: {
        phoneContact: contactByPhone,
        emailContact: contactByEmail
      }
    }
  };

  if (!freeformText) {
    delete answers.tasks.task1;
  }

  if (!likelyToComeBack) {
    delete answers.tasks.task2;
  }

  return answers
}