import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import Cookies from 'js-cookie';
import cn from 'classnames';
import { Container, GridCol, Heading } from '@legalshield/adonis-ux-framework';
import { SubscriptionResource, SubscriptionStatus } from '@legalshield/frontend-commons/dist/sdk/subscriptions';

import AssociateCard from '../AssociateCard/AssociateCard';
import CardGrid from '../../Card/CardGrid';
import { Entitlement, PlanFamilies } from './Entitlement/Entitlement';
import { EntitlementPresenter } from '../../../presenters';
import { LinkToAccess } from '../../LinkToAccess/LinkToAccess';
import { MembershipAlerts } from './Alerts/MembershipAlerts';
import { MembershipsEmptyState } from './EmptyState/EmptyState';
import { SkeletonCard } from '../../SkeletonCard/SkeletonCard';
import { Status } from '@legalshield/frontend-commons/dist/sdk/entitlements';
import { camelize } from '../../../utils/utils';

import { useGetSubscriptionsByIdentityId } from '../../../components/Payments/hooks/useSubscriptions';
import { useGetMultiplePrecancelStatuses } from './hooks/useGetMultiplePrecancelStauses';

import './Memberships.scss';

export interface MembershipsProps {
  products: EntitlementPresenter[];
  associates: EntitlementPresenter[];
  isLoading?: boolean;
  isSuccess?: boolean;
}

type MembershipCardsProps = {
  showNoProductsCopy: boolean;
  associates: EntitlementPresenter[];
  orderedPlanFamilies: PlanFamilies;
};

const MembershipCards: React.FC<MembershipCardsProps> = ({ showNoProductsCopy, associates, orderedPlanFamilies }) => {
  if (showNoProductsCopy)
    return (
      <CardGrid>
        <MembershipsEmptyState />
      </CardGrid>
    );

  return (
    <CardGrid>
      <AssociateCard count={associates.length} />
      {Object.keys(orderedPlanFamilies).map((family) => {
        return orderedPlanFamilies[family].map((presenter: EntitlementPresenter, index: number | null | undefined) => (
          <GridCol key={index}>
            <Entitlement
              key={index}
              index={index}
              presenter={presenter}
              family={family}
              orderedPlanFamilies={orderedPlanFamilies}
            />
          </GridCol>
        ));
      })}
    </CardGrid>
  );
};

const Memberships: FC<MembershipsProps> = ({ products, associates, isLoading, isSuccess }) => {
  const subscriptions = useGetSubscriptionsByIdentityId();
  const [showNoProductsCopy, setShowNoProductsCopy] = useState(false);
  const [showAfterPurchaseMessage, setShowAfterPurchaseMessage] = useState(false);
  const [showCallMessage, setShowCallMessage] = useState(false);
  const [orderedPlanFamilies, setOrderedPlanFamilies] = useState({} as PlanFamilies);
  const [showDeclinedPayment, setShowDeclinedPayment] = useState(false);
  const [hasLoaded, setHasLoaded] = useState(false);

  useEffect(() => {
    if (isSuccess) {
      const timer = setTimeout(() => {
        setHasLoaded(true);
      }, 100);
      return () => clearTimeout(timer);
    }
  }, [isSuccess]);

  const friendlyIds = useMemo(() => {
    if (!subscriptions?.data) return [];
    return subscriptions?.data?.map((sub: SubscriptionResource) => sub.source?.friendlyId).filter(Boolean);
  }, [subscriptions?.data]);

  const precancelStatusQueries = useGetMultiplePrecancelStatuses(friendlyIds);

  const isAnySubscriptionPendingCancel = useMemo(() => {
    if (!subscriptions?.data || Object.keys(precancelStatusQueries).length === 0) return false;

    const NO_LONGER_EMPLOYED = 'G';

    return subscriptions?.data.some((subscription: SubscriptionResource) => {
      const isPrecancel = subscription?.status === SubscriptionStatus.PRECANCEL;
      const friendlyId = subscription?.source?.friendlyId;
      if (!friendlyId) return false;

      const query = precancelStatusQueries[friendlyId];
      const statuses = query?.data;
      if (!statuses) return false;

      return statuses.some((status) => isPrecancel && status.pendingCancelFlag === NO_LONGER_EMPLOYED);
    });
  }, [subscriptions?.data, precancelStatusQueries]);

  const purchaseInfoCookieParsed = useMemo(() => {
    return JSON.parse(Cookies.get('purchase_info') || '{}');
  }, []);

  const afterPurchaseMessage = useCallback(() => {
    if (purchaseInfoCookieParsed?.purchase_complete) {
      const ts = Math.round(new Date().getTime() / 1000);
      const tsYesterday = ts - 24 * 3600;
      if (purchaseInfoCookieParsed?.purchase_complete - tsYesterday > 0) {
        setShowAfterPurchaseMessage(true);
      } else {
        setShowCallMessage(true);
      }
    } else {
      setShowNoProductsCopy(true);
    }
  }, [purchaseInfoCookieParsed?.purchase_complete]);

  const orderingPlans = useCallback(() => {
    setShowCallMessage(false);
    setShowNoProductsCopy(false);
    setShowAfterPurchaseMessage(false);
    const ordered: PlanFamilies = {};
    products.forEach((product) => {
      const key = camelize(product?.entitlement?.product?.productFamily);
      if (ordered[key]) {
        ordered[key].push(product);
        return;
      }
      ordered[key] = [] as EntitlementPresenter[];
      ordered[key].push(product);
    });
    Object.keys(ordered).forEach((key) => {
      ordered[key]
        .sort((a, b) => {
          return new Date(a.entitlement.updatedAt).getTime() - new Date(b.entitlement.updatedAt).getTime();
        })
        .reverse();
    });
    setOrderedPlanFamilies(ordered);
  }, [products]);

  useEffect(() => {
    if (products.length === 0 && associates.length === 0) {
      afterPurchaseMessage();
    } else {
      orderingPlans();
      setShowNoProductsCopy(false);
    }
  }, [products, afterPurchaseMessage, orderingPlans]);

  const hasSuspendedEntitlements = useMemo(() => {
    return Object.keys(orderedPlanFamilies).some((family) => {
      return orderedPlanFamilies[family].some((presenter) => {
        return presenter.entitlement.status === Status.SUSPENDED;
      });
    });
  }, [orderedPlanFamilies]);

  useEffect(() => {
    setShowDeclinedPayment(true);
    if (hasSuspendedEntitlements || isAnySubscriptionPendingCancel) {
      setShowDeclinedPayment(true);
    } else {
      setShowDeclinedPayment(false);
    }
  }, [hasSuspendedEntitlements, isAnySubscriptionPendingCancel]);

  return (
    <Container background="light-gray" className={cn('memberships-list', { 'memberships-list--expanded': hasLoaded })}>
      <MembershipAlerts
        showAfterPurchaseMessage={showAfterPurchaseMessage}
        showCallMessage={showCallMessage}
        showDeclinedPayment={showDeclinedPayment}
        setShowDeclinedPayment={setShowDeclinedPayment}
        isAnySubscriptionPendingCancel={isAnySubscriptionPendingCancel}
      />
      <Heading as="T20" text={string_table.MEMBERSHIPS_TITLE} classNames={['mt-4']} />
      {isLoading ? (
        <SkeletonCard />
      ) : (
        <MembershipCards
          showNoProductsCopy={showNoProductsCopy}
          associates={associates}
          orderedPlanFamilies={orderedPlanFamilies}
        />
      )}
      <LinkToAccess />
    </Container>
  );
};

export default Memberships;
