import React, { FC } from 'react';
import { Button, Grid, GridRow, GridCol, Heading, Image, Text } from '@legalshield/adonis-ux-framework';

import SomethingWentWrong from '../../images/SomethingWentWrong.svg';

import './PageNotFound.scss';

const PageNotFound: FC = () => {
  const imageUrl = SomethingWentWrong;
  const imageAlt = string_table.PAGE_NOT_FOUND_IMAGE_ALT;

  return (
    <Grid>
      <GridRow>
        <GridCol classNames={'page-not-found-center'}>
          <Heading as="T28" classNames={['pb-6', 'pt-10']} text={string_table.PAGE_NOT_FOUND_HEADING} />
          <Text textSize="medium" text={string_table.PAGE_NOT_FOUND_SUBTEXT} />
          <Image alt={imageAlt} src={imageUrl} />
          <Button
            variant="primary"
            widthLong="100%"
            onClick={() => {
              history.back();
            }}
            label={string_table.PAGE_NOT_FOUND_BUTTON_TEXT}
          />
        </GridCol>
      </GridRow>
    </Grid>
  );
};
export default PageNotFound;
