import { Questions, SDKHeaders } from '@legalshield/frontend-commons';
import { useQuery } from '@tanstack/react-query';

const questionsApi = new Questions.Api();

export function useGetQuestionsById(questionsId: string) {
  return useQuery({
    enabled: !!questionsId,
    queryFn: async () => {
      if (!questionsId) {
        return null;
      }
    
      const { data: questionnaire } = await questionsApi.v1.productsQuestionsGetSpecificQuestionnaire(questionsId, SDKHeaders.getVersionConfig(0));
      return questionnaire;
    },
    queryKey: ['question', questionsId],
  });
};
