import React, { FC } from 'react';
import { Container, Heading, Icon, Text } from '@legalshield/adonis-ux-framework';

import CardButton from '../CardButton/CardButton';
import { NavButton } from '../../NavigationBar/NavigationBar';

interface EntitlementCardProps extends NavButton {
  onClick?: () => void;
  children?: React.ReactElement;
  subtitle?: string;
  className?: string;
}

const EntitlementCard: FC<EntitlementCardProps> = ({
  name,
  iconName,
  to,
  target,
  text,
  subtitle,
  onClick,
  children,
}) => (
  <CardButton to={to} onClick={onClick} showArrow={true} target={target}>
    <Container flexbox flexDirection="column" classNames={['p-5']}>
      <Container flexbox flexDirection="row" alignItems="center">
        <Icon name={iconName} size="large" />
        <Heading as="T20" text={name} classNames={['ml-3']} />
      </Container>
      {text || subtitle || children ? (
        <Container flexbox flexDirection="column" classNames={['pt-4']}>
          {text && (
            <Text textSize="large" textWeight="semibold">
              {text}
            </Text>
          )}
          {subtitle && <Text classNames={['mt-2']} text={subtitle} />}
          {children}
        </Container>
      ) : null}
    </Container>
  </CardButton>
);

export default EntitlementCard;
