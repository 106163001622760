/* eslint-disable sort-keys */
import React, { createContext, useContext, useReducer } from 'react';
import { ISubscriptionResource } from '../hooks/utils/subscription-utils';
import PrecancelModal from './PrecancelModal';
import { usePaymentLastFour } from '../hooks/utils/usePaymentLastFour';

export type PrecancelModalStatus = 'hidden' | 'open' | 'submitting' | 'error';

export type PrecancelModalState = {
  status: PrecancelModalStatus;
  subscription: ISubscriptionResource | null;
  paymentMethodId: string | null;
  error?: string;
};

type ShowModalAction = {
  type: 'SHOW_MODAL';
  payload: {
    subscription: ISubscriptionResource;
    paymentMethodId: string;
  };
};

export type HideModalAction = {
  type: 'HIDE_MODAL';
};

export type SubmitAction = {
  type: 'SUBMIT';
};

export type SubmitSuccessAction = {
  type: 'SUBMIT_SUCCESS';
};

export type SubmitFailureAction = {
  type: 'SUBMIT_FAILURE';
  error: string;
};

export type PrecancelModalAction =
  | ShowModalAction
  | HideModalAction
  | SubmitAction
  | SubmitSuccessAction
  | SubmitFailureAction;

const precancelModalReducer = (
  state: PrecancelModalState,
  action: PrecancelModalAction,
): PrecancelModalState => {
  switch (action.type) {
    case 'SHOW_MODAL':
      return {
        status: 'open',
        subscription: action.payload.subscription,
        paymentMethodId: action.payload.paymentMethodId,
      };
    case 'SUBMIT':
      if (state.status !== 'open') return state;
      return {
        ...state,
        status: 'submitting',
      };
    case 'SUBMIT_SUCCESS':
      return {
        status: 'hidden',
        subscription: null,
        paymentMethodId: null,
      };
    case 'SUBMIT_FAILURE':
      return {
        ...state,
        status: 'error',
        error: action.error,
      };
    case 'HIDE_MODAL':
      return {
        status: 'hidden',
        subscription: null,
        paymentMethodId: null,
      };
    default:
      return state;
  }
};

export const PrecancelModalContext = createContext<{
  state: PrecancelModalState;
  dispatch: React.Dispatch<PrecancelModalAction>;
}>({
  state: {
    status: 'hidden',
    subscription: null,
    paymentMethodId: null,
  },
  dispatch: () => undefined,
});

type PrecancelModalProviderProps = {
  children: React.ReactNode;
};

export const PrecancelModalProvider: React.FC<PrecancelModalProviderProps> = ({ children }) => {
  const initialState: PrecancelModalState = {
    status: 'hidden',
    subscription: null,
    paymentMethodId: null,
  };
  const [state, dispatch] = useReducer(precancelModalReducer, initialState);
  const lastFourDigits = usePaymentLastFour(state.paymentMethodId, state.subscription?.status);

  return (
    <PrecancelModalContext.Provider value={{ state, dispatch }}>
      {children}
      {state.status !== 'hidden' && state.subscription && (
        <PrecancelModal
          subscription={state.subscription}
          lastFour={lastFourDigits}
          onClose={() => dispatch({ type: 'HIDE_MODAL' })}
        />
      )}
    </PrecancelModalContext.Provider>
  );
};

export const usePrecancelModal = () => {
  const context = useContext(PrecancelModalContext);
  if (!context) {
    throw new Error('usePrecancelModal must be used within a PrecancelModalProvider');
  }
  return context;
};

