import React, { FC } from 'react';
import { Layout } from '../../Layout/Layout';
import { Button, Container, Heading, Image, Text } from '@legalshield/adonis-ux-framework';
import { useNavigate } from 'react-router-dom';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import logo from './cancel-confirmation.svg';

const CancelConfirmation: FC = () => {
  const navigate = useNavigate();
  const handleButtonClick = () => {
    navigate('/payments');
  };

  return (
    <Layout>
      <Container flexbox flexDirection="column" alignItems="center" background="light-gray">
        <div style={{ width: '375px' }} className="mb-9'">
          <Image src={logo} alt="Cancel Confirmation Image" />
        </div>
        <Container
          flexbox
          flexDirection="column"
          alignItems="center"
          background="light-gray"
          classNames={['mb-10']}
          style={{ gap: '2rem' }}
        >
          <Heading as="T39" text={string_table.CANCEL_PLAN_CONFIRMATION_HEADING} />
          <Text
            text={string_table.CANCEL_PLAN_CONFIRMATION_TEXT_REFUND}
            style={{ maxWidth: '386px', textAlign: 'center' }}
          />
        </Container>
        <Button label={string_table.DONE} stretch style={{ maxWidth: '386px' }} onClick={handleButtonClick} />
      </Container>
    </Layout>
  );
};

export default CancelConfirmation;
