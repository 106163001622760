import React, { FC, JSX } from 'react';
import { Link } from 'react-router-dom';
import { Container, Icon } from '@legalshield/adonis-ux-framework';

import './CardButton.scss';

interface CardButtonProps {
  to?: string;
  onClick?: () => void;
  showArrow?: boolean;
  iconName?: string;
  imageSrc?: string;
  heading?: string;
  target?: string;
  text?: string;
  children?: JSX.Element;
}

const CardButton: FC<CardButtonProps> = ({ to, onClick, showArrow = true, children }) => (
  <Link to={to} onClick={onClick} className="lsux-card card-button card-button--clickable">
    <Container flexbox flexDirection="row">
      {children}
      {showArrow && (
        <Container background="none" classNames={['card-button__icon_container']}>
          <Icon name="arrow_chevron_right" size="medium" color="N800" />
        </Container>
      )}
    </Container>
  </Link>
);

export default CardButton;
