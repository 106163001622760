// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.button-container {
  width: 350px;
  transition: width 0.25s ease-in-out;
}

@media (max-width: 768px) {
  .button-container {
    width: 100%;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Payments/CancelPlanSelector/CancelPlanSelector.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,mCAAA;AACF;;AAEA;EACE;IACE,WAAA;EACF;AACF","sourcesContent":[".button-container {\n  width: 350px;\n  transition: width 0.25s ease-in-out;\n}\n\n@media (max-width: 768px) {\n  .button-container {\n    width: 100%\n  }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
