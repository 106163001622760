import React from 'react';
import { Card, Checkbox, Icon, Text } from '@legalshield/adonis-ux-framework';
import { Subscriptions } from '@legalshield/frontend-commons';

import { ISubscriptionResource } from '../hooks/utils/subscription-utils';
import { BenefitsList } from './BenefitsList';

function getBillingFrequency(billingPeriod: Subscriptions.BillingPeriod) {
  switch (billingPeriod) {
    case Subscriptions.BillingPeriod.DAILY:
      return 'day';
    case Subscriptions.BillingPeriod.WEEKLY:
      return 'week';

    case Subscriptions.BillingPeriod.QUARTERLY: 
      return '3 mo';
    case Subscriptions.BillingPeriod.BIANNUAL:
      return '6 mo';
    case Subscriptions.BillingPeriod.ANNUAL:
      return 'year';
    case Subscriptions.BillingPeriod.MONTHLY:
    default:
      return 'mo';
  }
}

type SubscriptionCardProps = {
  isSelected: boolean;
  subscription: ISubscriptionResource;
  onSelect: (subscriptionId: string) => void;
}
export function SubscriptionCard({ isSelected, onSelect, subscription }: SubscriptionCardProps) {
  const locale = pplsi?.market.split('-')[0] ?? 'en';
  const name = subscription.productBrochure?.friendlyNames?.find((fn) => fn.locale === locale)?.name ?? 'Unknown Plan Name';
  const price = subscription.price?.toFixed(2);
  const billingFrequency = getBillingFrequency(subscription.billingFrequency.period);
  const isLegalSubscription = subscription.productBrochure?.productFamily?.toLowerCase() === 'legal';
  const icon = isLegalSubscription ? 'apps_legal_shield' : 'apps_id_shield';
  const selectedStyle = isSelected ? { border: '3px solid var(--p200)' } : {};
  return (
    <Card style={selectedStyle}>
      <div className="p-5" style={{ borderBottom: '1px solid var(--n300)', display: 'flex', gap: '16px', alignItems: 'center' }}>
        <Checkbox name={`checkbox-${subscription.id}`} checked={isSelected} onClick={() => onSelect(subscription.id)} />
        <Icon name={icon} size="xlarge" />
        <div>
          <Text text={name} textSize="extra-large" />
          <div className="mt-2">
            <Text text={`$${price}/${billingFrequency}`} textSize="medium" />
          </div>
        </div>
      </div>
      <BenefitsList legalPlan={isLegalSubscription} />
    </Card>
  );
}