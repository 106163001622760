import React, { FC, Fragment } from 'react';
import { Container, GridCol, Heading, Image, Text } from '@legalshield/adonis-ux-framework';

import CardButton from '../../buttons/CardButton/CardButton';
import Config from '../../../config';
import URLs from '../../../utils/url';

export interface AssociateCardProps {
  count: number;
}

const AssociateCard: FC<AssociateCardProps> = ({ count }) => {
  const getAppUrl = () => {
    return URLs.getLSAppUrl(Config.currentEnv.apps.associate);
  };
  const url = getAppUrl();

  const associatesLabel = count >= 1 ? string_table.PLANS_ASSOCIATE_AGREEMENTS : string_table.PLANS_ASSOCIATE_AGREEMENT;

  if (count === 0) return <Fragment />;

  return (
    <GridCol>
      <CardButton to={url} showArrow={true} target="_blank">
        <Container background="none" flexbox flexDirection="column" classNames={['p-5']}>
          <Container background="none" flexbox flexDirection="row" alignItems="center" alignContent="center">
            <Image
              src={'https://design.api.' + pplsi.realmDomain + '/assets/logos/pplsipeople-square-logo.svg'}
              alt={'PPLSI Logo'}
              style={{
                width: '32px',
                height: '32px',
              }}
            />
            <Heading as="T20" text={string_table.PLANS_ASSOCIATE} classNames={['ml-2']} />
          </Container>
          <Container flexbox flexDirection="column" classNames={['pt-4']}>
            <Text textSize="large" textWeight="semibold">
              {string_table.PLANS_ASSOCIATE_AGREEMENTS}
            </Text>
            <Text
              as="p"
              textSize="small"
              classNames={['pt-3', 'card-button__text']}
            >{`${count} ${associatesLabel}`}</Text>
          </Container>
        </Container>
      </CardButton>
    </GridCol>
  );
};

export default AssociateCard;
