import React, { FC, useLayoutEffect, useEffect } from 'react';
import { Navigate, Outlet, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { Container, Footer, Layout } from '@legalshield/adonis-ux-framework';
import { Login } from '@legalshield/frontend-commons';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { useLDClient, withLDProvider } from 'launchdarkly-react-client-sdk';
import { clarity } from 'react-microsoft-clarity';

import FAQ from '../FAQ/FAQ';
import Multifactor from '../Multifactor/Multifactor';
import Profile from '../Profile/Profile';
import Notifications from '../Notifications/Notifications';
import Overview from '../Overview/Overview';
import PageNotFound from '../PageNotFound/PageNotFound';
import Payments from '../Payments/Payments';
import ProductDetails from '../Payments/ProductDetails/ProductDetails';
import Resources from '../Resources/Resources';
import Security from '../Security/Security';
import Setup from '../Setup/Setup';
import Unsubscribe from '../Unsubscribe/Unsubscribe';
import UnsubscribeByEmail from '../Unsubscribe/UnsubscribeByEmail';
import Wallet from '../Wallet/Wallet';
import { NavigationBar } from '../NavigationBar/NavigationBar';
import { Password } from '../Security/Password/Password';
import { CancelPlanSelector } from '../Payments/CancelPlanSelector/CancelPlanSelector';
import UpgradePlan from '../Payments/UpgradePlan/UpgradePlan';
import { useLaunchDarkly } from '../../hooks/useLaunchDarkly';
import Config, { LsEnv } from '../../config';
import { useGetEntitlements } from '../../hooks/useEntitlements';
import { useGetProfile } from '../../hooks/useProfile';
import LinkMembership, { CodeType } from '../Payments/Subscriptions/LinkMembership/LinkMembership';
import { CancelPlanSurvey } from '../Payments/CancelPlanSurvey/CancelPlanSurvey';
import CancelConfirmation from '../Payments/CancelConfirmation/CancelConfirmation';

/** Map the deprecated routes to their new URL paths */
const deprecatedRoutes: Record<string, string> = {
  '/home': '/overview',
  '/mfa': '/security/mfa',
  '/overview/notifications': '/notifications',
  '/overview/profile': '/profile',
  '/overview/subscriptions': '/payments',
  '/preferences': '/notifications',
  '/subscriptions': '/payments',
};

/** Scroll to top when route changes */
const ScrollWrapper = ({ children }) => {
  const location = useLocation();

  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);
  return children;
};

function RedirectToLinkMembership() {
  const { pathname, search } = useLocation();
  const pathName = pathname.slice(1); // Removes leading '/'
  const searchParams = new URLSearchParams(search);

  // Define the valid routes for redirect
  const routeToType: Record<string, CodeType> = {
    access: CodeType.MemberNumber,
    activate: CodeType.AccountCode,
    associate: CodeType.AssociateNumber,
  };
  const validRedirectPathNames = Object.keys(routeToType);

  /**
   * This determines the codeType from the pathname. If a user is redirected from /access, /associate, /activate,
   * they should have the matching codeType passed into the redirect URL. If a user is given a mismatched url,
   * e.g. (/access?codeType=accountCode), this will correct that to `/access?codeType=memberNumber`.
   */
  if (validRedirectPathNames.includes(pathName)) {
    const codeType = routeToType[pathName];
    searchParams.set('codeType', codeType);
    return <Navigate to={`/link-membership?${searchParams.toString()}`} replace />;
  }

  return <Navigate to={pathname} />;
}

const App: FC = () => {
  const navigate = useNavigate();

  ads_set_title(string_table.TITLE, true);

  const { enableClarity, clickToCancelConfig } = useLaunchDarkly();

  useEffect(() => {
    if (enableClarity) {
      clarity.init('nc0vrklyhw');
    }
  }, [enableClarity]);

  // Redirect to /overview from root path, this logic is for cachi
  useEffect(() => {
    if (location.pathname === '/') {
      navigate('/overview', { replace: true });
    }
  }, [location.pathname, navigate]);

  if (!pplsi?.authNPayload?.sub) {
    return (
      <Routes>
        <Route path="/">{(window.location.href = Login.getLoginUrl())}</Route>
        <Route path="/p/identities/:identityId/profile/unsubscribeFromCommunication" element={<Unsubscribe />} />
        <Route path="/p/profile/:emailAddress/unsubscribeFromCommunication" element={<UnsubscribeByEmail />} />
      </Routes>
    );
  }

  return (
    <Routes>
      <Route path="/" element={<LayoutWithNav />}>
        {/* Unsubscribe Routes */}
        <Route path="/p/identities/:identityId/profile/unsubscribeFromCommunication" element={<Unsubscribe />} />
        <Route path="/p/profile/:emailAddress/unsubscribeFromCommunication" element={<UnsubscribeByEmail />} />
        {/* Active Routes */}
        <Route index element={<Navigate to="/overview" replace />} />
        <Route path="overview" element={<Overview />} />
        <Route path="profile" element={<Profile />} />
        {clickToCancelConfig?.enabled && <Route path="/payments/cancel-survey" element={<CancelPlanSurvey />} />}
        {clickToCancelConfig?.enabled && (
          <Route path="/payments/cancel-confirmation" element={<CancelConfirmation />} />
        )}
        {clickToCancelConfig?.enabled && <Route path="/payments/cancel" element={<CancelPlanSelector />} />}
        <Route path="payments/details/:id" element={<ProductDetails />} />
        <Route path="subscriptions/details/:id" element={<ProductDetails />} />
        <Route path="wallet" element={<Navigate to="/payments/wallet" replace />} />
        <Route path="/payments/:id/upgrade" element={<UpgradePlan />} />
        <Route path="payments" element={<Navigate to="/payments/subscriptions" replace />} />
        <Route path="payments/*" element={<Payments />} />
        <Route path="wallet" element={<Wallet />} />
        <Route path="security" element={<Security />} />
        <Route path="security/password" element={<Password />} />
        <Route path="security/mfa" element={<Multifactor />} />
        <Route path="resources" element={<Resources />} />
        <Route path="notifications" element={<Notifications />} />
        <Route path="faq" element={<FAQ />} />
        <Route path="setup/*" element={<Setup />} />
        {/* Link Membership routes */}
        <Route path="/access" element={<RedirectToLinkMembership />} />
        <Route path="/activate" element={<RedirectToLinkMembership />} />
        <Route path="/associate" element={<RedirectToLinkMembership />} />
        <Route path="/link-membership" element={<LinkMembership />} />

        {/* Deprecated Routes */}
        {Object.entries(deprecatedRoutes).map(([from, to]) => (
          <Route key={from} path={from} element={<Navigate to={to} />} />
        ))}
      </Route>
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
};

const LayoutWithNav: FC = () => {
  const ldClient = useLDClient();
  const { data: entitlements } = useGetEntitlements();
  const { data: profile } = useGetProfile();

  useEffect(() => {
    if (entitlements?.length > 0 && profile?.addresses?.length > 0) {
      const channels = entitlements.map((e) => e.source?.channel).filter((c) => !!c);
      const states = profile.addresses.map((a) => a.administrativeArea).filter((s) => !!s);
      const countries = profile.addresses?.map((a) => a.country ?? '').filter((c) => !!c);
      ldClient.identify({
        kind: 'user',
        email: pplsi.authNPayload?.ual ?? '',
        key: pplsi.authNPayload?.sub ?? '',
        language: pplsi.market.split('-')[0] ?? '',
        name: pplsi.authNPayload?.sub_name ?? '',
        channels,
        states: states?.length > 0 ? [...new Set(states)] : [],
        countries: countries?.length > 0 ? [...new Set(countries)] : [],
      });
    }
  }, [entitlements, profile]);

  return (
    <ScrollWrapper>
      <Container background="light-gray">
        <Layout variant="scroll" navigation={<NavigationBar />}>
          <Outlet />
          {Config.getLsEnv() === LsEnv.Local && <ReactQueryDevtools initialIsOpen={false} />}
          <Footer
            classNames={['lsux-layout-padding']}
            align={window.location.pathname.includes('/setup') ? 'center' : 'right'}
            htmlContent={footerHtml}
          />
        </Layout>
      </Container>
    </ScrollWrapper>
  );
};

export default withLDProvider({
  clientSideID: LDClientID,
  context: {
    kind: 'multi',
    organization: {
      key: 'PPLSI',
      name: 'PPLSI',
    },
    user: {
      email: pplsi.authNPayload?.ual ?? '',
      key: pplsi.authNPayload?.sub ?? '',
      language: pplsi.market.split('-')[0] ?? '',
      name: pplsi.authNPayload?.sub_name ?? '',
    },
  },
  options: {
    bootstrap: 'localStorage',
  },
})(App);
