import React, { FC } from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { Container, GridCol } from '@legalshield/adonis-ux-framework';

import CardGrid from '../Card/CardGrid';

const SkeletonEntitlementCard: FC = () => (
  <div className="lsux-card card-button p-5">
    <Container flexbox flexDirection="column" background="white">
      <Container flexbox flexDirection="row" alignItems="center" background="none">
        <Skeleton width={32} height={32} />
        <Skeleton width={150} height={24} className="ml-4" />
      </Container>
      <Skeleton count={1} width={260} className="mt-5" />
      <Skeleton count={1} width={220} className="mt-3" />
    </Container>
  </div>
);

export const SkeletonCard: FC = () => (
  <CardGrid classNames={['skeleton__container']}>
    <GridCol>
      <SkeletonEntitlementCard />
    </GridCol>
    <GridCol>
      <SkeletonEntitlementCard />
    </GridCol>
  </CardGrid>
);
