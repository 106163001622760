import React, { FC, Fragment } from 'react';
import { Container, Title } from '@legalshield/adonis-ux-framework';
import ChartLine from '../../images/ChartLine.svg';
import BalanceScale from '../../images/BalanceScale.svg';
import CardGrid from '../Card/CardGrid';
import QuickActionsCard from '../../components/buttons/QuickActionsCard/QuickActionsCard';
import { useLaunchDarkly } from '../../hooks/useLaunchDarkly';
import AssociateOffice from '../../images/AssociateOffice.svg';
import Users from '../../images/Users.svg';
import Config from '../../config';
import URLs from '../../utils/url';

export const QuickActions: FC = () => {
  const { quickActionLinks } = useLaunchDarkly();

  const getManageMembersUrl = () => {
    return URLs.getLSAppUrl(Config.currentEnv.apps.legal, '/members/');
  };
  const getRequestConsultUrl = () => {
    return URLs.getLSAppUrl(Config.currentEnv.apps.legal, '/request-consult/');
  };
  const getIDShieldUrl = () => {
    return URLs.getLSAppUrl(Config.currentEnv.apps.ids);
  };

  const getAssociateOfficeUrl = () => {
    return URLs.getLSAppUrl(Config.currentEnv.apps.associate);
  };

  return (
    <Fragment>
      {quickActionLinks && (
        <>
          <Container
            flexbox
            background="light-gray"
            alignItems="flex-start"
            justifyContent="flex-start"
            classNames={['mt-6']}
          >
            <Title text="Quick actions" textSize="large" />
          </Container>
          <CardGrid>
            <QuickActionsCard
              imageAlt="Talk to my attorney"
              imageSrc={BalanceScale}
              name="Talk to my attorney"
              target="_blank"
              to={getRequestConsultUrl()}
            />
            <QuickActionsCard
              imageAlt="Check my credit score"
              imageSrc={ChartLine}
              name="Check my credit score"
              target="_blank"
              to={getIDShieldUrl()}
            />
            <QuickActionsCard
              imageAlt="Add a family member"
              imageSrc={Users}
              name="Add a family member"
              target="_blank"
              to={getManageMembersUrl()}
            />
            <QuickActionsCard
              imageAlt="Go to Associate Office website"
              imageSrc={AssociateOffice}
              name="Go to Associate Office website"
              target="_blank"
              to={getAssociateOfficeUrl()}
            />
          </CardGrid>
        </>
      )}
    </Fragment>
  );
};
