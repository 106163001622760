import React, { FC, Fragment, useState, useEffect, useRef } from 'react';
import { useSearchParams, useLocation, useNavigate } from 'react-router-dom';
import {
  Alert,
  Button,
  Container,
  Form,
  FormField,
  Heading,
  Icon,
  Image,
  Input,
  InputValidationStatus,
  Text,
  Tooltip,
} from '@legalshield/adonis-ux-framework';
import GtmService from '../../../../services/gtm.service';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import successIcon from '../../../Payments/ConfirmUpgradeModal/successIcon.svg';
import CallForQuestions from '../CallForQuestions/CallForQuestions';
import ActivateService from '../../../../services/activate.service';
import { InvitationPresenter } from '../../../../presenters';
import { IInvitation, InvitationResponseSecurityQuestion } from '../../../../interfaces/invitation.interface';
import { OWNER_CANNOT_ACCEPT_INVITATION_API_ERROR } from '../../../../config';
import { populateTemplateString } from '../../../../utils/stringFormat';
import { isStorybook } from '../../../Payments/utils/environment';
import { useGetEntitlements } from '../../../../hooks/useEntitlements';
import './LinkMembership.scss';
import { Layout } from '../../../Layout/Layout';

interface UnknownCodeTooltipProps {
  tooltipText: string;
}

export interface IField {
  hint?: string;
  status?: 'valid' | 'invalid' | 'warning';
  value?: string | boolean | number;
  checked?: boolean;
  match?: string;
}

enum WizardSteps {
  ChooseCodeType,
  EnterCode,
  SecurityQuestions,
  Success,
}

export enum CodeType {
  MemberNumber = 'memberNumber',
  AssociateNumber = 'associateNumber',
  AccountCode = 'accountCode',
  Unknown = 'unknown',
}

const LinkMembership: FC = () => {
  // Form hooks
  const [searchParams] = useSearchParams();
  const urlParamMemberNumber = searchParams.get('code');
  const urlParamCodeType = searchParams.get('codeType');

  const [step, setStep] = useState(urlParamCodeType ? WizardSteps.EnterCode : WizardSteps.ChooseCodeType);
  const [memberNumber, setMemberNumber] = useState('');
  const [securityQuestions, setSecurityQuestions] = useState<Array<InvitationResponseSecurityQuestion>>([]);
  const [securityAnswers, setSecurityAnswers] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [validationStatus, setValidationStatus] = useState<InputValidationStatus>(null);
  const [invitationPresenterState, setInvitationPresenterState] = useState<InvitationPresenter | null>(null);
  const [codeType, setCodeType] = useState<CodeType>((urlParamCodeType as CodeType) ?? CodeType.Unknown);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitBtnDisabled, setSubmitBtnDisabled] = useState(false);

  useEffect(() => {
    if (step === WizardSteps.SecurityQuestions) {
      setSubmitBtnDisabled(true);
    }
  }, [step]);

  const [isDirty, setIsDirty] = useState(false);

  const { refetch } = useGetEntitlements();

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (memberNumber) {
      setIsDirty(true);
    }
  }, [memberNumber, isDirty]);

  useEffect(() => {
    if (!isStorybook() && isDirty) {
      GtmService.linkMembershipFillInputGtmEvent('linkmember_form_start', pplsi?.authNPayload?.sub);
    }
  }, [isDirty]);

  useEffect(() => {
    if (urlParamMemberNumber && !memberNumber) setMemberNumber(urlParamMemberNumber);
    if (location.pathname.includes('/activate')) handleStepChange(WizardSteps.EnterCode);
    if (location.pathname.includes('/access')) handleStepChange(WizardSteps.EnterCode);
    if (location.pathname.includes('/associate')) handleStepChange(WizardSteps.EnterCode);
    if (location.pathname.includes('/link-membership') && urlParamCodeType) {
      handleStepChange(WizardSteps.EnterCode);
    } else if (location.pathname.includes('/link-membership') && urlParamMemberNumber) {
      setCodeType(CodeType.Unknown);
      handleStepChange(WizardSteps.EnterCode);
    }
  }, [location.pathname, urlParamMemberNumber, urlParamCodeType]);

  const accountCodeClicked = (codeType) => () => {
    setCodeType(codeType);
    handleStepChange(WizardSteps.EnterCode);
  };

  const subtitleCopy: Record<CodeType, string> = {
    [CodeType.MemberNumber]: string_table.LINK_MEMBERSHIP_MEMBER_NUMBER_SUBTITLE,
    [CodeType.AssociateNumber]: string_table.LINK_MEMBERSHIP_ASSOCIATE_NUMBER_SUBTITLE,
    [CodeType.AccountCode]: string_table.LINK_MEMBERSHIP_ACCOUNT_CODE_SUBTITLE,
    [CodeType.Unknown]: string_table.LINK_MEMBERSHIP_UNKNOWN_SUBTITLE,
  };

  const titleCopy: Record<CodeType, string> = {
    [CodeType.MemberNumber]: string_table.LINK_MEMBERSHIP_MODAL_MEMBER_NUMBER_TITLE,
    [CodeType.AssociateNumber]: string_table.LINK_MEMBERSHIP_MODAL_ASSOCIATE_NUMBER_TITLE,
    [CodeType.AccountCode]: string_table.LINK_MEMBERSHIP_MODAL_ACCOUNT_CODE_TITLE,
    [CodeType.Unknown]: string_table.LINK_MEMBERSHIP_MODAL_UNKNOWN_TITLE,
  };

  const inputLabelCopy: Record<CodeType, string> = {
    [CodeType.MemberNumber]: string_table.LINK_MEMBERSHIP_MEMBER_NUMBER_LABEL,
    [CodeType.AssociateNumber]: string_table.LINK_MEMBERSHIP_ASSOCIATE_NUMBER_LABEL,
    [CodeType.AccountCode]: string_table.LINK_MEMBERSHIP_ACCOUNT_CODE_LABEL,
    [CodeType.Unknown]: string_table.LINK_MEMBERSHIP_UNKNOWN_LABEL,
  };

  const handleDone = () => {
    navigate('/overview', { state: { reloadEntitlements: true } });
  };

  const invalidCredentials = populateTemplateString(string_table.INVITATION_INVALID_CREDENTIALS, {
    memberNumber: inputLabelCopy[codeType].charAt(0).toLowerCase() + inputLabelCopy[codeType].slice(1),
  });

  const resetFlow = () => {
    setMemberNumber('');
    setSecurityQuestions([]);
    setSecurityAnswers({});
    setIsLoading(false);
    setErrorMessage('');
    setValidationStatus(null);
    setStep(WizardSteps.ChooseCodeType);
  };

  const handleStepChange = (step: WizardSteps) => {
    setIsLoading(true);
    setErrorMessage('');
    setValidationStatus('valid');

    if (step === WizardSteps.EnterCode) {
      setValidationStatus(null);
      setStep(step);
      setIsLoading(false);
    } else {
      setTimeout(() => {
        setValidationStatus(null);
        setStep(step);
        setIsLoading(false);
      }, 1000);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitBtnDisabled(true);
    setIsLoading(true);
    try {
      await handleSecurityQuestionsSubmit(e);
    } catch {
      setSubmitBtnDisabled(false);
      setIsLoading(false);
    }
  };

  const handleApiFailure = () => {
    setErrorMessage(string_table.APP_FAILURE);
    setIsLoading(false);
  };

  const acceptInvitation = async (invitation: IInvitation) => {
    try {
      const acceptResponse = await ActivateService.acceptInvitation({
        code: memberNumber,
        invitationId: invitation.id,
        version: invitation.version,
      });
      if (acceptResponse.statusCode === 200) {
        handleStepChange(WizardSteps.Success);
        if (!isStorybook()) {
          GtmService.linkMembershipOutcomeGtmEvent(
            'linkmember_accept_invite_outcome',
            acceptResponse.statusCode,
            pplsi?.authNPayload?.sub,
          );
        }
      } else if (
        acceptResponse.statusCode === 422 &&
        acceptResponse.bodyContent &&
        JSON.parse(acceptResponse.bodyContent)?.message === OWNER_CANNOT_ACCEPT_INVITATION_API_ERROR
      ) {
        // If the owner cannot accept the invitation, throw an error
        setErrorMessage(
          string_table.LINK_MEMBERSHIP_USER_CANNOT_ACCEPT_INVITATION ?? 'User cannot accept this invitation.',
        );
        setValidationStatus('invalid');
      } else {
        setErrorMessage(invalidCredentials);
      }
    } catch {
      handleApiFailure();
    } finally {
      setIsLoading(false);
    }
  };

  const handleMemberNumberSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    setIsLoading(true);
    setErrorMessage(null);
    try {
      const submitCodeResponse = await ActivateService.submitCode(memberNumber);

      if (submitCodeResponse.statusCode === 200 && submitCodeResponse.body) {
        if (!isStorybook()) {
          GtmService.linkMembershipOutcomeGtmEvent(
            'linkmember_submit_code_outcome',
            submitCodeResponse.statusCode,
            pplsi?.authNPayload?.sub,
          );
        }

        const invitation: IInvitation = submitCodeResponse.body;
        const invitationPresenter = new InvitationPresenter(invitation);

        setInvitationPresenterState(invitationPresenter);

        if (invitationPresenter.hasSecurityQuestions()) {
          setSecurityQuestions(invitationPresenter.securityQuestions());
          handleStepChange(WizardSteps.SecurityQuestions);
        } else {
          acceptInvitation(invitation);
          if (!isStorybook()) {
            GtmService.linkMembershipOutcomeGtmEvent(
              'linkmember_accept_invitation_outcome ',
              submitCodeResponse.statusCode,
              pplsi?.authNPayload?.sub,
            );
          }
        }
      } else {
        setErrorMessage(invalidCredentials);
        setValidationStatus('invalid');
      }
    } catch (error) {
      handleApiFailure();
      setValidationStatus('invalid');
      if (!isStorybook()) {
        GtmService.linkMembershipOutcomeGtmEvent('linkmember_error', error, pplsi?.authNPayload?.sub);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const acceptInvitationFailure = () => {
    setErrorMessage(string_table.VERIFY_IDENTITY_FAILURE);
  };

  const handleSecurityQuestionsSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    setIsLoading(false);
    setErrorMessage(null);

    try {
      const invitation = invitationPresenterState?.invitation;
      if (invitation) {
        const transformedSecurityAnswers = Object.keys(securityAnswers).map((key) => ({
          id: key, // Replace `key` with the actual property name for the ID.
          answer: securityAnswers[key], // Replace `securityAnswers[key]` with the actual property name for the answer.
        }));

        const acceptResponse = await ActivateService.acceptInvitation({
          code: invitation.verification?.code || '',
          invitationId: invitation.id,
          securityQuestions: transformedSecurityAnswers,
          version: invitation.version,
        });

        if (acceptResponse.statusCode === 200) {
          if (!isStorybook()) {
            GtmService.linkMembershipOutcomeGtmEvent(
              'linkmember_accept_response_outcome',
              acceptResponse.statusCode,
              pplsi?.authNPayload?.sub,
            );
          }

          setValidationStatus('valid');
          handleStepChange(WizardSteps.Success);
        } else if (
          acceptResponse.statusCode === 422 &&
          acceptResponse.bodyContent &&
          JSON.parse(acceptResponse.bodyContent)?.message === OWNER_CANNOT_ACCEPT_INVITATION_API_ERROR
        ) {
          // If the owner cannot accept the invitation, throw an error
          setErrorMessage(
            string_table.LINK_MEMBERSHIP_USER_CANNOT_ACCEPT_INVITATION ?? 'User cannot accept this invitation.',
          );
          setValidationStatus('invalid');
        } else {
          setValidationStatus('invalid');
          acceptInvitationFailure();
        }
      } else {
        setValidationStatus('invalid');
        handleApiFailure();
      }
    } catch {
      setValidationStatus('invalid');
      handleApiFailure();
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  const memberNumberChange = (memberNumber) => {
    setValidationStatus(null);
    setErrorMessage('');
    setMemberNumber(memberNumber);
  };

  const handleAnswerChange = (questionId, value) => {
    setValidationStatus(null);
    setErrorMessage('');
    setSecurityAnswers({ ...securityAnswers, [questionId]: value });
    // Update the security answers state
    const updatedAnswers = { ...securityAnswers, [questionId]: value };
    setSecurityAnswers(updatedAnswers);

    // Check if all questions have answers
    const allQuestionsAnswered = securityQuestions.every(
      (question) => updatedAnswers[question.id] && updatedAnswers[question.id].trim() !== '',
    );

    // Only enable the button if all questions are answered
    setSubmitBtnDisabled(!allQuestionsAnswered);
    setIsSubmitting(false);
  };

  useEffect(() => {
    if (location.state?.reloadEntitlements) {
      refetch();
    }
  }, [location.state?.reloadEntitlements, refetch]);

  const continueBtnDisabled = isLoading || !memberNumber;

  const continueBtnText = isLoading ? 'Loading...' : string_table.LINK_MEMBERSHIP_CONTINUE;

  const codeTypeFormatted = inputLabelCopy[codeType].charAt(0).toLowerCase() + inputLabelCopy[codeType].slice(1);

  const dontKnowMyCodeText = populateTemplateString(string_table.LINK_MEMBERSHIP_DONT_KNOW_MY_CODE, {
    memberNumber: codeTypeFormatted,
  });

  const UnknownCodeTooltip: FC<UnknownCodeTooltipProps> = ({ tooltipText }) => {
    const [showTooltip, setShowTooltip] = useState(false);
    const timeoutRef = useRef<NodeJS.Timeout | null>(null);

    const handleMouseEnter = () => {
      timeoutRef.current = setTimeout(() => {
        setShowTooltip(true);
      }, 300);
    };

    const handleMouseLeave = () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
        timeoutRef.current = null;
      }
      setShowTooltip(false);
    };

    return (
      <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
        {showTooltip && (
          <Tooltip
            placement="top"
            arrow
            text=" "
            theme="material"
            tooltipHTML={
              <>
                <p style={{ fontWeight: 'normal', fontSize: '12px' }}>{string_table.TOOLTIP_P_1}</p>
                <p style={{ fontWeight: 'normal', fontSize: '12px' }}>{string_table.TOOLTIP_P_2}</p>
                <p style={{ fontWeight: 'normal', fontSize: '12px' }}>
                  {string_table.TOOLTIP_P_3_A}
                  <strong>{string_table.TOOLTIP_P_3_B}</strong>
                  {string_table.TOOLTIP_P_3_C}
                </p>
              </>
            }
          >
            <Container
              background="none"
              flexbox
              alignContent="center"
              justifyContent="center"
              width={'100%'}
              classNames={'pt-5'}
            >
              <Icon name="warning_circle_warning" size="small" classNames={['mr-2']} />
              <Text textSize="small" text={tooltipText} />
            </Container>
          </Tooltip>
        )}

        {!showTooltip && (
          <Container
            background="none"
            flexbox
            alignContent="center"
            justifyContent="center"
            width={'100%'}
            classNames={'pt-5'}
          >
            <Icon name="warning_circle_warning" size="small" classNames={['mr-2']} />
            <Text textSize="small" text={tooltipText} />
          </Container>
        )}
      </div>
    );
  };

  const crumbs = [
    { label: string_table.OVERVIEW_TITLE, link: '/overview' },
    { label: string_table.LINK_MEMBERSHIP_TEXT, link: '/link-membership' },
  ];

  return (
    <Layout crumbs={crumbs} title={step === WizardSteps.Success ? '' : 'Link Membership'}>
      {step === WizardSteps.ChooseCodeType && (
        <Container classNames={'link-membership__container'} flexbox flexDirection="column">
          <Heading
            as="T28"
            text={string_table.LINK_MEMBERSHIP_ACCOUNT_CODE_TYPE_TITLE}
            classNames="link-membership__code-type-title"
          />

          <Text
            textSize="medium"
            text={string_table.LINK_MEMBERSHIP_ACCOUNT_CODE_TYPE_SUBTITLE}
            classNames="link-membership__code-type-subtitle"
          />

          <div className="link-membership__code-type-buttons">
            <Button
              variant="primary"
              label={string_table.LINK_MEMBERSHIP_CODE_TYPE_MEMBER_NUMBER}
              onClick={accountCodeClicked(CodeType.MemberNumber)}
            />

            <Button
              variant="primary"
              label={string_table.LINK_MEMBERSHIP_CODE_TYPE_ACCOUNT_CODE}
              onClick={accountCodeClicked(CodeType.AccountCode)}
            />

            <Button
              variant="primary"
              label={string_table.LINK_MEMBERSHIP_CODE_TYPE_ASSOCIATE_NUMBER}
              onClick={accountCodeClicked(CodeType.AssociateNumber)}
            />
          </div>

          <UnknownCodeTooltip tooltipText={string_table.LINK_MEMBERSHIP_CANT_FIND_MY_NUMBER} />
        </Container>
      )}

      {step === WizardSteps.EnterCode && (
        <Container classNames={'link-membership__container'} flexbox flexDirection="column">
          <Form id="link-membership__security-questions" onSubmit={handleMemberNumberSubmit}>
            <Heading
              as="T28"
              text={titleCopy[codeType] ?? string_table.LINK_MEMBERSHIP_MODAL_UNKNOWN_TITLE}
              classNames={['mb-5']}
            />

            <Text
              textSize="large"
              text={subtitleCopy[codeType] ?? string_table.LINK_MEMBERSHIP_UNKNOWN_SUBTITLE}
              classNames={['mb-2']}
            />

            {errorMessage && <Alert severity="error">{string_table.LINK_MEMBERSHIP_INVALID_CREDENTIALS}</Alert>}

            <FormField
              id="membership-number"
              label={inputLabelCopy[codeType]}
              required
              validationHint={errorMessage}
              status={errorMessage ? 'invalid' : undefined}
              data-testid="user_entered_member_number"
            >
              <Input
                autoFocus
                placeholder={inputLabelCopy[codeType]}
                value={memberNumber}
                onChange={(e) => memberNumberChange(e.currentTarget.value)}
                required
                status={validationStatus}
                id="user_entered_member_number_no_secQ"
              />
            </FormField>

            <UnknownCodeTooltip tooltipText={dontKnowMyCodeText} />

            <Button
              variant="primary"
              label={continueBtnText}
              type="submit"
              disabled={continueBtnDisabled}
              stretch={true}
              classNames={['mt-5']}
            />

            <CallForQuestions phoneNumber="8006547757" />
          </Form>
        </Container>
      )}
      {step === WizardSteps.SecurityQuestions && (
        <Container
          classNames={'link-membership__container link-membership__security-container'}
          flexbox
          flexDirection="column"
        >
          <Form
            id="link-membership__security-questions"
            onSubmit={(e) => {
              e.preventDefault();
              handleSecurityQuestionsSubmit(e);
            }}
            className="link-membership__security-form"
          >
            <Heading
              as="T28"
              text={string_table.SECURITY_QUESTIONS_VERIFY_TITLE}
              className="link-membership__security-title"
            />

            <FormField id="membership-number" label="Member Number" className="link-membership__form-field">
              <Input
                placeholder={inputLabelCopy[codeType]}
                value={memberNumber}
                onChange={(e) => memberNumberChange(e.currentTarget.value)}
                required
                data-testid="user_entered_member_number"
                disabled
                className="link-membership__input"
              />
            </FormField>

            {/* Wrap the mapped elements in a Fragment */}
            <Fragment>
              {securityQuestions.map((question: InvitationResponseSecurityQuestion) => (
                <FormField
                  key={question.id}
                  id={`security-question-${question.id}`}
                  label={question.question}
                  required
                  validationHint={errorMessage}
                  status={validationStatus}
                  className="link-membership__form-field"
                >
                  <Input
                    autoFocus={question.id === securityQuestions[0].id}
                    classNames={['mb-2']}
                    id="user_entered_member_number_with_secQ"
                    onChange={(e) => handleAnswerChange(question.id, e.currentTarget.value)}
                    onKeyUp={(e) => {
                      if (e.key === 'Enter' && question.id === securityQuestions[securityQuestions.length - 1].id) {
                        handleSubmit(e);
                      }
                    }}
                    value={securityAnswers[question.id] || ''}
                  />
                </FormField>
              ))}
            </Fragment>

            <Button
              variant="primary"
              label={isSubmitting ? string_table.LOADING_TEXT : string_table.LINK_MEMBERSHIP_TEXT}
              onClick={handleSubmit}
              disabled={submitBtnDisabled}
              stretch={true}
              classNames={['mt-7']}
            />

            <CallForQuestions phoneNumber="8006547757" />
          </Form>
        </Container>
      )}
      {step === WizardSteps.Success && (
        <Container
          background="none"
          flexbox
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          classNames="link-membership__success-container"
        >
          <Image src={successIcon} alt="successIcon" height={48} width={48} className="link-membership__success-icon" />

          <Heading
            as="T20"
            text={string_table.LINK_MEMBERSHIP_SUCCESS_HEADING}
            classNames="link-membership__success-title"
          />

          <Text
            text={string_table.LINK_MEMBERSHIP_SUCCESS_TEXT}
            textSize="large"
            classNames="link-membership__success-text"
          />

          <Text
            text={string_table.LINK_MEMBERSHIP_SUCCESS_ALERT_TEXT}
            textSize="large"
            classNames="link-membership__success-text"
          />

          <Container
            background="none"
            flexbox
            flexDirection="column"
            alignItems="center"
            classNames="link-membership__success-buttons"
          >
            <Button
              variant="primary"
              label={string_table.LINK_MEMBERSHIP_LINK_ANOTHER}
              onClick={() => resetFlow()}
              classNames="link-membership__button"
            />

            <Button
              variant="secondary"
              label={string_table.DONE}
              onClick={handleDone}
              classNames="link-membership__button link-membership__button--secondary"
            />
          </Container>
        </Container>
      )}
    </Layout>
  );
};

export default LinkMembership;
