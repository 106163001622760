import React, { FC } from 'react';
import { buildURL, Button } from '@legalshield/adonis-ux-framework';

import Config from '../../../../config';
import EntitlementCard from '../../../buttons/EntitlementCard/EntitlementCard';
import GtmService from '../../../../services/gtm.service';
import URLs from '../../../../utils/url';
import { EntitlementPresenter } from '../../../../presenters';

export interface PlanFamilies {
  [key: string]: EntitlementPresenter[];
}

interface PlanFamilyProps {
  title: string;
  icon: string;
}

interface PlanFamilyTitles {
  [key: string]: PlanFamilyProps;
}

interface EntitlementProps {
  index: number | string;
  presenter: EntitlementPresenter;
  orderedPlanFamilies: PlanFamilies;
  family: string;
}

export const Entitlement: FC<EntitlementProps> = ({ presenter, index, family, orderedPlanFamilies }) => {
  const presenters = orderedPlanFamilies[family];
  const url = presenters.length > 0 ? getAppUrl(presenters[index]) : '/';

  const Launch = 'Launch';

  const productFamilyTitles: PlanFamilyTitles = {
    identity: { icon: 'id', title: 'IDShield' },
    legal: { icon: 'legal', title: 'LegalShield' },
  };

  function getAppUrl(presenter: EntitlementPresenter): string {
    switch (presenter.entitlement.product.productFamily) {
      case 'Identity':
        return buildURL(URLs.getLSAppUrl(Config.currentEnv.apps.ids), { e: presenter.entitlement.id });
      case 'Legal':
        return buildURL(URLs.getLSAppUrl(Config.currentEnv.apps.legal), { e: presenter.entitlement.id });
      default:
        return buildURL(URLs.getLSAppUrl(Config.currentEnv.apps.legal), { e: presenter.entitlement.id });
    }
  }

  function goToLaunch(presenter: EntitlementPresenter) {
    window.location.href = buildURL(URLs.getLSAppUrl(Config.currentEnv.apps.launch), {
      e: presenter.entitlement.id,
    });
  }

  const captureEntitlementClick = (title: string) => {
    // trigger GA4 custom event for view entitlement
    GtmService.viewEntitlementGtmEvent(title);
  };

  // Use the productFamilyTitles object to get the icon and title for the product family. This ensures it's always a valid value.
  const productFamily = family === 'identity' ? 'identity' : 'legal';

  return (
    <EntitlementCard
      iconName={`apps_${productFamilyTitles[productFamily]?.icon}_shield`}
      name={productFamilyTitles[productFamily]?.title}
      to={url}
      target="_blank"
      text={presenter.productFriendlyName()}
      subtitle={`${string_table.PRODUCTS_MEMBERNUMBER}: ${presenter.entitlementFriendlyId()}`}
      onClick={() => captureEntitlementClick(presenter.entitlement.product.productFamily)}
      data={presenter.entitlementFriendlyId()}
    >
      {presenter.hasLaunch() && (
        <div>
          <Button
            classNames={['mt-3', 'product-launch-button']}
            label={presenter.getSupplementFriendlyName(Launch)}
            onClick={(e) => {
              e.stopPropagation();
              goToLaunch(presenter);
            }}
            textSize="small"
            variant="secondary"
          />
        </div>
      )}
    </EntitlementCard>
  );
};
