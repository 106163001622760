import React, { useState } from 'react';
import { get } from 'lodash';
import { useLaunchDarkly } from '../../../hooks/useLaunchDarkly';
import { Layout } from '../../Layout/Layout';
import { Button, Card, Checkbox, Heading, Icon, Radio, RadioRoot, Spinner, Text, TextArea } from '@legalshield/adonis-ux-framework';
import { useGetQuestionsById } from '../hooks/useGetQuestionsById';

import './CancelPlanSurvey.scss';
import { useSubmitFeedback } from './useSubmitFeeback';
import { useNavigate } from 'react-router-dom';
import { getQuestionnaireNameByLocale } from './getQuestionnaireNameByLocale';

const locale = pplsi?.market ?? 'en-US';

// Like below as a result of an incorrect implementation in another lib the title and value are swapped
const defaultLikelyToReturnOptions = [
  { title: 'very-unlikely', value: string_table.CANCEL_PLAN_SURVEY_VERY_UNLIKELY_LABEL },
  { title: 'unlikely', value: string_table.CANCEL_PLAN_SURVEY_UNLIKELY_LABEL },
  { title: 'neutral', value: string_table.CANCEL_PLAN_SURVEY_NEUTRAL_LABEL },
  { title: 'likely', value: string_table.CANCEL_PLAN_SURVEY_LIKELY_LABEL },
  { title: 'very-likely', value: string_table.CANCEL_PLAN_SURVEY_VERY_LIKELY_LABEL },
];

export function CancelPlanSurvey() {
  const navigate = useNavigate();
  const { clickToCancelConfig = { enabled: false } } = useLaunchDarkly();
  const submitFeedbackMutation = useSubmitFeedback();
  const [freeformText, setFreeformText] = useState('');
  const [likelyToComeBack, setLikelyToComeBack] = useState('');
  const [contactByPhone, setContactByPhone] = useState(false);
  const [contactByEmail, setContactByEmail] = useState(false);
  const questionsId = clickToCancelConfig.enabled ? getQuestionnaireNameByLocale(locale, clickToCancelConfig.surveyQuestionsTemplate) : '';
  const { data: questions, isLoading: questionsLoading, isError: questionsIsError } = useGetQuestionsById(questionsId);

  if (!clickToCancelConfig.enabled) {
    return <Layout></Layout>;
  }

  const likelyToReturnOptions = get(questions, 'properties.tasks.properties.task2.properties.radio1.options', defaultLikelyToReturnOptions);

  return (
    <Layout title={string_table.CANCEL_PLAN_SURVEY_TITLE} subtitle="">
      {questionsLoading && <Spinner />}
      {questionsIsError && <Text text={string_table.CANCEL_PLAN_SURVEY_DATA_ERROR} />}
      {!questionsLoading && !questionsIsError && (
        <>
        <Button
          label={string_table.CANCEL_PLAN_SURVEY_SKIP_LABEL}
          onClick={() => navigate('/payments/cancel-confirmation')}
          variant="tertiary"
          iconRight='arrow_chevron_right_md'
          iconSize='medium'
          iconColor="P200"
        />

        <Card classNames={["py-8", "px-6", "mt-6"]}>
          <Heading as="T20" text={string_table.CANCEL_PLAN_SURVEY_FREEFORM_TEXT_TITLE} />
          <Text classNames={['mt-3']} text={string_table.CANCEL_PLAN_SURVEY_FREEFORM_TEXT_SUBTITLE} />
          <TextArea
            label='Feedback'
            classNames={['mt-5']}
            placeholder={string_table.CANCEL_PLAN_SURVEY_FREEFORM_TEXT_PLACEHOLDER}
            rows={5}
            onChange={(e: React.FormEvent<HTMLTextAreaElement>) => setFreeformText(e.currentTarget.value) }
          />
          <hr className="my-9" style={{ border: '1px var(--n350) solid', width: '100%'}} />
          <Heading as="T20" text={string_table.CANCEL_PLAN_SURVEY_RETURN_LIKLIHOOD_TITLE} />
          <div className="mt-5">
            <RadioRoot className='radio-container'>
              {likelyToReturnOptions?.map((ltrOption: { title: string, value: string}) => {
                // BEGIN: Nonsense
                const title = ltrOption.value;
                const value = ltrOption.title;
                // END Nonsense
                return (
                  <div
                    className="py-4 px-5 likely-to-return-option"
                    key={title}
                    style={{
                      backgroundColor: `var(${getBackground(value)})`,
                      border: likelyToComeBack === value ? getActiveBorder(value) : 'none',
                    }}
                    onClick={() => setLikelyToComeBack(value)}
                  >
                      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', gap: '12px' }}>
                        <Radio
                          checked={likelyToComeBack === value}
                          value={value} 
                        />
                        <Text style={{ textAlign: 'center' }} text={title} textSize="medium" />
                      </div>
                  </div>  
                )
              })}
            </RadioRoot>
          </div>
          <hr className="my-9" style={{ border: '1px var(--n350) solid', width: '100%'}} />
          <Heading as="T20" text={string_table.CANCEL_PLAN_SURVEY_CONTACT_TITLE} />
          <div className="mt-5 contact-container">
          <div className={`p-5 contact-question ${contactByPhone ? 'selected' : ''}`}>
              <Checkbox
                checked={contactByPhone}
                name="phone-checkbox"
                rightLabel={string_table.CANCEL_PLAN_SURVEY_CONTACT_PHONE_LABEL}
                onClick={() => setContactByPhone(!contactByPhone)}
              />
              <Icon name="system_mobile" />
            </div>
            <div className={`p-5 contact-question ${contactByEmail ? 'selected' : ''}`}>
              <Checkbox
                checked={contactByEmail}
                name="email-checkbox"
                rightLabel={string_table.CANCEL_PLAN_SURVEY_CONTACT_EMAIL_LABEL}
                onClick={() => setContactByEmail(!contactByEmail)}
              />
              <Icon name="comm_email_default" />
            </div>
          </div>
          <hr className="my-9" style={{ border: '1px var(--n350) solid', width: '100%'}} />
          <div style={{ width: '100%', display: 'flex', justifyContent: 'center'}}>
            <div className="button-container">
              <div style={{ display: 'flex', flexDirection: 'row', gap: '8px', alignItems: 'center' }}>
                {clickToCancelConfig.enabled &&
                  <Button
                    disabled={!likelyToComeBack && !freeformText}
                    label={string_table.CANCEL_PLAN_SURVEY_CONTACT_SUBMIT_LABEL}
                    variant='primary'
                    stretch
                    onClick={async () => {
                      try {
                        await submitFeedbackMutation.mutateAsync({
                          freeformText,
                          likelyToComeBack,
                          contactByPhone,
                          contactByEmail,
                          questionnaireName: clickToCancelConfig.surveyQuestionsTemplate

                        });
                        navigate('/payments/cancel-confirmation');
                      } catch (_) { /* empty */ }
                    }}
                  />
                }

                {(submitFeedbackMutation.isLoading) && <div><Spinner classNames={['m-0']} /></div>}
              </div>
            </div>
          </div>

        </Card>
      </>
      )}
    </Layout>
  );
};

function getBackground(value: string) {
  switch(value) {
    case 'very-unlikely':
    case 'unlikely':
      return '--r10';
    case 'very-likely':
    case 'likely':
      return '--g10';
    default:
      return '--y10';
  }
}

function getActiveBorder(value: string) {
  switch(value) {
    case 'very-unlikely':
    case 'unlikely':
      return '2px var(--r200) solid';
    case 'very-likely':
    case 'likely':
      return '2px var(--g200) solid';
    default:
      return '2px var(--y200) solid';
  }
}