import { useFlags } from 'launchdarkly-react-client-sdk';

type ReferralProgramFlag = {
  url?: string;
};

type ClickToCancelConfigFlagDisabled = {
  enabled: false;
};

type ClickToCancelConfigFlagEnabled = {
  enabled: true;
  allowedGroupIds: string[];
  surveyQuestionsTemplate: string;
};

export type ClickToCancelConfig = ClickToCancelConfigFlagEnabled | ClickToCancelConfigFlagDisabled;

export type LaunchDarklyFlags = {
  collectFeedback: boolean;
  clickToCancelConfig: ClickToCancelConfig;
  disablePlacementStatusButton: boolean;
  enableClarity: boolean;
  enablePaymentDropdownOnPendingCancel: boolean;
  enableStartup: boolean;
  familyManagementLink: boolean;
  quickActionLinks: boolean;
  referralProgram: ReferralProgramFlag;
  showBrokerReports: boolean;
  showBuyNowUrl: boolean;
  showCommissionsInNav: boolean;
  showCompensationInNav: boolean;
  showMyTeamGenealogyCard: boolean;
  showMyTeamInNav: boolean;
  showMyTeamLegacyPlacementCard: boolean;
  showMyTeamPlacementCard: boolean;
  showNewObr: boolean;
  showNewTaxForms: boolean;
  showSitePreferencesOfferingCards: boolean;
  startupMaximumEffectiveDateAge: number;
  upgradeFlow: boolean;
  useNewEngageTokenEndpoint: boolean;
  useNewMyStatisticsUrl: boolean;
  useNewProspectTokenEndpoint: boolean;
};

export const useLaunchDarkly = () => useFlags<LaunchDarklyFlags>();
