import React, { FC } from 'react';
import { Accordion, Button, Container, Text } from '@legalshield/adonis-ux-framework';
import { useNavigate } from 'react-router-dom';

import { ISubscriptionResource } from '../hooks/utils/subscription-utils';
import { Subscription } from './Subscription/Subscription';

import './Subscriptions.scss';
import { SubscriptionCancelLink } from './Subscription/SubscriptionCancelLink';
import { canClickToCancel } from './Subscription/canClickToCancel';
import { useLaunchDarkly } from '../../../hooks/useLaunchDarkly';

interface SubscriptionsProps {
  subscriptions: ISubscriptionResource[];
  isLoading: boolean;
}

const Subscriptions: FC<SubscriptionsProps> = ({ subscriptions = [], isLoading }) => {
  const navigate = useNavigate();
  const { clickToCancelConfig } = useLaunchDarkly();
  const showSubscriptionCancel = canClickToCancel(clickToCancelConfig, subscriptions);

  return (
    <Container classNames={['accounts-subscriptions']} flexbox flexDirection="column">
      <Accordion.Root type="multiple" defaultValue={[subscriptions?.map[0]?.id ?? '']}>
        {isLoading ? (
          <>
            <Subscription isLoading={true} />
            <Subscription isLoading={true} />
          </>
        ) : subscriptions.length ? (
          <>
            {subscriptions.map((sub) => (
              <Subscription key={sub?.id} subscription={sub} />
            ))}
            {showSubscriptionCancel && <SubscriptionCancelLink />}
          </>
        ) : (
          <>
            <Text text="No plans found" classNames={['my-4']} />
            <Button
              label={string_table.LINK_MEMBERSHIP_SUBMIT_BUTTON_TEXT}
              onClick={() => navigate('/link-membership')}
              classNames={['mt-5']}
            />
          </>
        )}
      </Accordion.Root>
    </Container>
  );
};

export default Subscriptions;
