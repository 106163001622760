import React, { FC } from 'react';
import { Layout } from '../Layout/Layout';
import CardGrid from '../Card/CardGrid';
import URLs from '../../utils/url';
import Config from '../../config';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
import memberPerksLogo from '../../images/memberPerksLogo.svg';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
import formsLogo from '../../images/formsLogo.svg';
import AccountCard from '../buttons/AccountCard/AccountCard';
import { GridCol } from '@legalshield/adonis-ux-framework';
import { NavButton } from '@components/NavigationBar/NavigationBar';

const forms: NavButton = {
  data: URLs.getLSAppUrl(Config.currentEnv.apps.forms),
  imageAlt: 'Forms icon',
  imageSrc: formsLogo,
  name: 'Forms',
  text: string_table.RESOURCES_FORMS_DESCRIPTION,
};

const memberPerks: NavButton = {
  data: 'https://legalshield.perkspot.com/login',
  imageAlt: 'Member Perks icon',
  imageSrc: memberPerksLogo,
  name: 'MemberPerks',
  text: string_table.RESOURCE_MEMBERSHIP_PERKS_DESCRIPTION,
};

export const resourcesButton = [forms, memberPerks];

const Resources: FC = () => {
  const crumbs = [
    { label: string_table.OVERVIEW_BREADCRUMB, link: '/overview' },
    { label: string_table.RESOURCES_TITLE, link: '/resources' },
  ];

  return (
    <Layout crumbs={crumbs} title={string_table.RESOURCES_TITLE} subtitle={string_table.RESOURCES_DESCRIPTION}>
      <CardGrid>
        {resourcesButton.map((button, index) => (
          <GridCol key={index}>
            <AccountCard
              key={index}
              name={button.name}
              imageSrc={button.imageSrc}
              to={button.data}
              text={button.text}
              data={button.data}
            />
          </GridCol>
        ))}
      </CardGrid>
    </Layout>
  );
};

export default Resources;
