// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.radio-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 8px;
}
.radio-container .likely-to-return-option {
  border-radius: 8px;
  cursor: pointer;
  width: 130px;
}

.contact-container {
  display: flex;
  flex-direction: row;
  gap: 8px;
}
.contact-container .contact-question {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px var(--n350) solid;
  border-radius: 8px;
  width: 50%;
}
.contact-container .contact-question.selected {
  border: 2px var(--p200) solid;
}

.button-container {
  width: 350px;
  transition: width 0.25s ease-in-out;
}

@media (max-width: 795px) {
  .radio-container {
    flex-direction: column;
  }
  .radio-container .likely-to-return-option {
    width: 100%;
  }
  .contact-container {
    flex-direction: column;
  }
  .contact-container .contact-question {
    width: 100%;
  }
  .button-container {
    width: 100%;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Payments/CancelPlanSurvey/CancelPlanSurvey.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,eAAA;EACA,QAAA;AACF;AACE;EACE,kBAAA;EACA,eAAA;EACA,YAAA;AACJ;;AAGA;EACE,aAAA;EACA,mBAAA;EACA,QAAA;AAAF;AAEE;EACE,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,6BAAA;EACA,kBAAA;EACA,UAAA;AAAJ;AAEI;EACE,6BAAA;AAAN;;AAKA;EACE,YAAA;EACA,mCAAA;AAFF;;AAKA;EACE;IACE,sBAAA;EAFF;EAIE;IACE,WAAA;EAFJ;EAMA;IACE,sBAAA;EAJF;EAME;IACE,WAAA;EAJJ;EAQA;IACE,WAAA;EANF;AACF","sourcesContent":[".radio-container {\n  display: flex;\n  flex-direction: row;\n  flex-wrap: wrap;\n  gap: 8px;\n\n  .likely-to-return-option {\n    border-radius: 8px;\n    cursor: pointer;\n    width: 130px;\n  }\n}\n\n.contact-container {\n  display: flex;\n  flex-direction: row;\n  gap: 8px;\n\n  .contact-question {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    border: 1px var(--n350) solid;\n    border-radius: 8px;\n    width: 50%;\n\n    &.selected {\n      border: 2px var(--p200) solid;\n    }\n  }\n}\n\n.button-container {\n  width: 350px;\n  transition: width 0.25s ease-in-out;\n}\n\n@media (max-width: 795px) {\n  .radio-container {\n    flex-direction: column;\n\n    .likely-to-return-option {\n      width: 100%;\n    }\n  }\n\n  .contact-container {\n    flex-direction: column;\n\n    .contact-question {\n      width: 100%;\n    }\n  }\n\n  .button-container {\n    width: 100%\n  }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
