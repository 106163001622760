import { useMutation, useQueryClient } from '@tanstack/react-query';
import { SubscriptionResource, Api as SubscriptionsApi } from '@legalshield/frontend-commons/dist/sdk/subscriptions';
import { useGetSubscriptionBySubscriptionId } from './useSubscription';

const subscriptionsApi = new SubscriptionsApi();

const identityId = pplsi?.authNPayload?.sub;

type OutstandingInvoice = {
  totalBillingAmount: number;
  invoicesBehind: number;
  paymentMethodId: string;
  chargeDate: string;
};

type PostSubscriptionCatchupProps = {
  subscriptionId: string;
  payload: OutstandingInvoice;
  subscriptionVersion: number | undefined;
};

const postSubscriptionCatchup = async ({
  subscriptionId,
  payload,
  subscriptionVersion,
}: PostSubscriptionCatchupProps) => {
  const { data } = await subscriptionsApi.v1.subscriptionsCatchUp(identityId, subscriptionId, payload, {
    headers: {
      'If-Match': subscriptionVersion,
    },
    withCredentials: true,
  });

  return { data, subscriptionId };
};

export const useSubscriptionCatchup = (subscriptionId: string) => {
  const { data: subscriptionData } = useGetSubscriptionBySubscriptionId(subscriptionId);
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (props: PostSubscriptionCatchupProps) =>
      postSubscriptionCatchup({
        ...props,
        subscriptionVersion: subscriptionData?.version,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['subscriptions'] });
      queryClient.invalidateQueries({ queryKey: ['subscription', subscriptionId] });
    },
  });
};
