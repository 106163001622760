import { useMutation, useQueryClient } from '@tanstack/react-query';
import { /*SDKHeaders,*/ Subscriptions } from '@legalshield/frontend-commons';

import { useBannerAlert } from '../../Alerts/Banner/context/BannerAlertContext';
// import { validateStatusResponse } from '../utils/utils';

// const subscriptionsApi = new Subscriptions.Api();

const identityId = pplsi?.authNPayload?.sub;

export function useCancelSubscriptions() {
  const queryClient = useQueryClient();
  const { bannerAlertDispatch } = useBannerAlert();
  return useMutation({
    mutationFn: async (subscriptions: Subscriptions.SubscriptionResource[]) => {
      if (!subscriptions?.length) {
        throw new Error('Subscriptions must be provided');
      }

      // TODO: uncomment when ready subscription service gets cancelled subscriptions to iSeries
      // await Promise.all(subscriptions.map((subscription: Subscriptions.SubscriptionResource) => 
      //   subscriptionsApi.v1.subscriptionsCancelSubscription(
      //   identityId,
      //   subscription.id,
      //   { reason: Subscriptions.CancelReason.MEMBER_REQUESTED_CANCEL },
      //   SDKHeaders.getVersionConfig(subscription?.version, true, validateStatusResponse()),
      // )));

      const subscriptionIds = subscriptions.map((subscription: Subscriptions.SubscriptionResource) => subscription.id);
      const res = await fetch('/v1/subscriptions/cancel', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ identityId, subscriptionIds }),
      });

      if (!res.ok) {
        throw new Error(res.statusText);
      };

      return subscriptions;
    },
    onSuccess: (subscriptions: Subscriptions.SubscriptionResource[]) => {
      queryClient.invalidateQueries(['subscriptions']);
      subscriptions.forEach((subscription: Subscriptions.SubscriptionResource) => {
        queryClient.invalidateQueries(['subscription', subscription.id]);
      });
    },
    onError: (error: Error) => {
      console.error(`Error canceling subscriptions: ${error}`);
      bannerAlertDispatch({ message: string_table.ALERT_ERROR, severity: 'error', type: 'DISPLAY' });
    },
  });
}
