import React, { useEffect, useState } from 'react';
import { Button, Spinner, Text } from '@legalshield/adonis-ux-framework';
import { useNavigate } from 'react-router-dom';

import { useLaunchDarkly } from '../../../hooks/useLaunchDarkly';

import { Layout } from '../../Layout/Layout';
import { useBannerAlert } from '../../Alerts/Banner/context/BannerAlertContext';

import './CancelPlanSelector.scss';
import { useGetSubscriptionsByIdentityId } from '../hooks/useSubscriptions';
import { SubscriptionCard } from './SubscriptionCard';
import { Subscriptions } from '@legalshield/frontend-commons';
import { useCancelSubscriptions } from './useCancelSubscriptions';
import { getCancellableSubscriptions } from './getCancellableSubscriptions';


export function CancelPlanSelector() {
  const { clickToCancelConfig = { enabled: false } } = useLaunchDarkly();
  const cancelMutation = useCancelSubscriptions();
  const { bannerAlertDispatch } = useBannerAlert();
  const [selectedSubscriptions, setSelectedSubscriptions] = useState<Subscriptions.SubscriptionResource[]>([]);
  const navigate = useNavigate();

  const { data: subscriptions, isLoading: subscriptionsLoading, isError: subscriptionsIsError, error: subscriptionsError } = useGetSubscriptionsByIdentityId();

  useEffect(() => {
    if (cancelMutation.isError || subscriptionsIsError) {
      console.error(`Error canceling subscriptions: ${cancelMutation.error || subscriptionsError}`);
      bannerAlertDispatch({ message: string_table.ALERT_ERROR, severity: 'error', type: 'DISPLAY' });
    }
  }, [cancelMutation.isError, subscriptionsIsError])

  if (!clickToCancelConfig.enabled) {
    return <Layout></Layout>;
  }

  const cancelButtonDisabled = subscriptionsLoading || !selectedSubscriptions.length || cancelMutation.isLoading;

  const cancelablleSubscriptions = getCancellableSubscriptions(clickToCancelConfig, subscriptions);
  
  return (
    <Layout title={string_table.CANCEL_PLAN_PAGE_TITLE} subtitle={string_table.CANCEL_PLAN_PAGE_SUBTITLE}>
      {subscriptionsLoading && <Spinner />}
      {!cancelablleSubscriptions?.length && !subscriptionsLoading && <Text text="There are no subscriptions eligible to cancel." textSize='large' />}
      {cancelablleSubscriptions?.map((subscription) =>
        <div key={subscription.id} className="mt-5">
          <SubscriptionCard
            isSelected={selectedSubscriptions.some((s) => s.id === subscription.id)}
            subscription={subscription}
            onSelect={() => {
              if (selectedSubscriptions.some((s) => s.id === subscription.id)) {
                setSelectedSubscriptions(selectedSubscriptions.filter((s) => s.id !== subscription.id));
              } else {
                setSelectedSubscriptions([...selectedSubscriptions, subscription]);
              }
            }}
          />
        </div>
      )}
      {!!cancelablleSubscriptions?.length && 
        <div className="pt-6 pb-9" style={{ display: 'flex', justifyContent: 'center' }}>
          <div className="button-container" style={{ display: 'flex', flexDirection: 'column' }}>
            <div style={{ display: 'flex', flexDirection: 'row', gap: '8px', alignItems: 'center' }}>
              {clickToCancelConfig.enabled &&
                <Button
                  disabled={cancelButtonDisabled}
                  label={string_table.CANCEL_PLAN_CONFIRM}
                  variant='primary'
                  stretch
                  onClick={async () => {
                    try {
                      await cancelMutation.mutateAsync(selectedSubscriptions);
                      navigate('/payments/cancel-survey');
                    } catch (_) { /* empty */ }
                  }}
                />
              }
              {(cancelMutation.isLoading) && <div><Spinner classNames={['m-0']} /></div>}
            </div>
          </div>
        </div>
      }
    </Layout>
  );
}
