import React from 'react';
import { Button, Image, Text, Title } from '@legalshield/adonis-ux-framework';

type BenefitProps = {
  title: string;
  subtitle: string;
  image: string;
  onLearnMoreClick: () => void;
}
export function Benefit({ title, subtitle, image, onLearnMoreClick }: BenefitProps) {
  return (
    <div style={{ display: 'flex', alignContent: 'flex-start', gap: '16px' }}>
      <div style={{ alignSelf: 'flex-start', minWidth: '48px', minHeight: '48px' }}>
        <Image alt={'imageAlt'} src={image} />
      </div>
      <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
        <Title text={title} textSize="large" />
        <Text text={subtitle} textSize="large" />
        <div style={{ width: 'fit-content' }}>
          <Button
            label={string_table.CANCEL_PLAN_LEARN_MORE}
            onClick={() => onLearnMoreClick()}
            variant="tertiary"
            iconRight='arrow_right_md'
            iconSize='medium'
            iconColor="P200"
          />
        </div>
      </div>
    </div>
  )
}