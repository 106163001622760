import React, { FC, useEffect, useState } from 'react';
import { Button, Heading, Icon } from '@legalshield/adonis-ux-framework';
import { useNavigate, useLocation } from 'react-router-dom';
import { UpgradeableSubscription, checkUpgradeStatus } from '../../Payments/utils/getUpgradeableSubscriptions';

import './UpgradeBanner.scss';

interface UpgradeBannerProps {
  subscriptionId: string;
  upgradesData: UpgradeableSubscription[];
}

const UpgradeBanner: FC<UpgradeBannerProps> = ({ subscriptionId, upgradesData }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [showBanner, setShowBanner] = useState<boolean>(false);

  // Extract friendlyIdArray from upgradesData
  const friendlyIdArray = upgradesData.map((upgradeData) => upgradeData.subscription.source.friendlyId);

  useEffect(() => {
    // If we're coming from an upgrade, don't show the banner initially
    if (location.state?.fromUpgrade) {
      setShowBanner(false);
      return;
    }

    const handleUpgradeStatus = async () => {
      try {
        if (friendlyIdArray && friendlyIdArray.length > 0) {
          const status = await checkUpgradeStatus(friendlyIdArray);
          // Show upgrade banner when plan upgrade is NOT pending
          setShowBanner(!status.planUpgradeIsPending);
        }
      } catch (error) {
        console.error('Error checking upgrade status:', error);
        setShowBanner(false);
      }
    };

    // Run immediately when the component mounts
    handleUpgradeStatus();
  }, [friendlyIdArray, location.state?.fromUpgrade]);

  const dismissBanner = () => {
    setShowBanner(false);
  };

  if (!showBanner) {
    return null;
  }

  return (
    <div className="upgrade-banner">
      <div className="upgrade-banner__content">
        <div className="upgrade-banner__content__left">
          <Heading as="T20" text={string_table.UPGRADE_BANNER_TITLE} />
          <Button
            label={string_table.UPGRADE_BANNER_BUTTON_TEXT}
            classNames={['mt-4']}
            onClick={
              upgradesData.length > 1
                ? () => navigate('/payments')
                : () => navigate(`/payments/${subscriptionId}/upgrade`, { state: { fromOverview: true } })
            }
          />
        </div>
        <div className="upgrade-banner__content__right">
          <Icon name="feedback_close" size="medium" onClick={dismissBanner} />
        </div>
      </div>
    </div>
  );
};

export default UpgradeBanner;
