import { Subscriptions } from '@legalshield/frontend-commons';
import { ClickToCancelConfig } from '../../../hooks/useLaunchDarkly';
import { ISubscriptionResource } from '../hooks/utils/subscription-utils';

const cancellableStatuses = [Subscriptions.SubscriptionStatus.ACTIVE, Subscriptions.SubscriptionStatus.PRECANCEL];

export function getCancellableSubscriptions(
  clickToCancelConfig?: ClickToCancelConfig,
  subscriptions?: ISubscriptionResource[],
) {
  if (!clickToCancelConfig?.enabled || !subscriptions?.length) {
    return [];
  }

  return subscriptions
    .filter((subscription) => {
      const groupId = subscription?.source?.groupId;
      return !groupId || clickToCancelConfig.allowedGroupIds.includes(groupId);
    })
    .filter((subscription) => subscription.productBrochure?.productFamily?.toLowerCase() !== 'associate')
    .filter((subscriptions) => cancellableStatuses.includes(subscriptions.status));
}
