import React, { useMemo, useRef, useState } from 'react';
import {
  Container,
  Modal,
  FormField,
  Checkbox,
  Button,
  Text,
  Link,
  Spinner,
  Input,
} from '@legalshield/adonis-ux-framework';
import { useGetOutstandingInvoices } from '../hooks/useGetOutstandingInvoices';
import { ISubscriptionResource } from '../hooks/utils/subscription-utils';
import { getFriendlyProductNameFromSubscription } from '../../../utils/getFriendlyName';
import { useSubscriptionCatchup } from '../hooks/useSubscriptionCatchup';
import { populateTemplateString } from '../../../utils/stringFormat';
import { getBillingFrequency } from '../utils/getBillingFrequency';
import { usePrecancelModal } from './PrecancelModalContext';
import { useHandleUpdatePaymentMethod } from '../hooks/useUpdatePaymentMethod';
import { UpdatePaymentMethod } from '@legalshield/frontend-commons/dist/sdk/subscriptions';
import { Users } from '@legalshield/frontend-commons/src/utils/users';
import { useGetPrecancelStatuses } from '../hooks/useGetPrecancelStatuses';
import { useQueryClient } from '@tanstack/react-query';

type PrecancelModalProps = {
  lastFour: any;
  subscription: ISubscriptionResource;
  onClose: () => void;
};

const PrecancelModal = ({ subscription, lastFour }: PrecancelModalProps) => {
  const NO_LONGER_EMPLOYED = 'G';
  const queryClient = useQueryClient();
  const { state, dispatch } = usePrecancelModal();

  const { data: invoicesData, isLoading: isInvoicesLoading } = useGetOutstandingInvoices(subscription?.id);
  const precancelStatuses = useGetPrecancelStatuses(subscription?.source?.friendlyId);
  const isGroupMemberPendingCancel = precancelStatuses?.data?.some(
    (status) => status?.pendingCancelFlag === NO_LONGER_EMPLOYED,
  );

  const [isChecked, setIsChecked] = useState(false);

  const todaysDate = new Date().toISOString().split('T')[0];
  const [date, setDate] = useState(todaysDate);
  const maxDate = new Date();
  const maxDays = 5;
  maxDate.setDate(maxDate.getDate() + maxDays);
  const maxDateFormatted = maxDate.toISOString().split('T')[0];
  const isValidDate = date >= todaysDate && date <= maxDateFormatted;

  const memberNumber = subscription?.source?.friendlyId;
  const invoicesBehind = invoicesData?.outstandingInvoices?.invoicesBehind;
  const totalBillingAmount = invoicesData?.outstandingInvoices?.totalBaseAmount;
  const billingFrequency = getBillingFrequency(subscription?.billingFrequency.period, true);
  const subscriptionPrice = invoicesData?.price;
  const friendlyProductName = getFriendlyProductNameFromSubscription(subscription);

  const { mutateAsync: catchupSubscription, isPending: isCatchupInProgress } = useSubscriptionCatchup(subscription?.id);
  const { mutateAsync: updatePaymentMethod } = useHandleUpdatePaymentMethod(subscription?.id);

  const handlePayAndRenew = async () => {
    dispatch({ type: 'SUBMIT' });
    try {
      const outstandingInvoice = {
        chargeDate: date,
        invoicesBehind,
        paymentMethodId: state?.paymentMethodId || subscription?.paymentMethodId,
        totalBillingAmount,
      };

      const catchUpPayload = {
        payload: outstandingInvoice,
        subscriptionId: subscription?.id,
        subscriptionVersion: subscription?.version,
      };

      await catchupSubscription(catchUpPayload);

      // Refetch to ensure we have the latest subscription version
      await queryClient.refetchQueries({ queryKey: ['subscription', subscription?.id] });

      const updatedPaymentMethod: UpdatePaymentMethod = {
        paymentMethodId: state?.paymentMethodId,
      };
      await updatePaymentMethod(updatedPaymentMethod);

      dispatch({ type: 'SUBMIT_SUCCESS' });
    } catch (e) {
      console.error('Pay and Renew Error', e);
      dispatch({ type: 'SUBMIT_FAILURE', error: e });
    } finally {
      dispatch({ type: 'HIDE_MODAL' });
    }
  };

  const TOS_LINK = 'http://pplsi.com/terms-service/';
  const RightLabel = () => (
    <>
      <Text as="span" text={string_table.PRECANCEL_TOS_TEXT} style={{ display: 'block' }} />
      <Link
        id="link-to-tos"
        decoration={true}
        href={TOS_LINK}
        target="_blank"
        rel="noopener noreferrer"
        text={string_table.PRECANCEL_TOS_LINK}
      />
    </>
  );

  const isSubmitting = state?.status === 'submitting';

  const ChargeDate = () => {
    const inputValueRef = useRef(todaysDate);
    const dateStatus = isValidDate ? 'valid' : 'invalid';
    const validationHint = isValidDate ? undefined : string_table.PRECANCEL_VALIDATION_HINT;

    const handleDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      event.preventDefault();
      inputValueRef.current = event.target.value;
      setDate(inputValueRef.current);
    };

    const handleDateBlur = (event: React.ChangeEvent<HTMLInputElement>) => {
      event.preventDefault();
      setDate(inputValueRef.current);
    };

    if (Users.isImpersonated()) {
      return (
        <FormField id="charge-date-form" validationHint={validationHint} leftLabel="Charge Date" required>
          <Input
            id="input-date"
            name="input-date"
            onChange={handleDateChange}
            onBlur={handleDateBlur}
            type="date"
            status={dateStatus}
            value={date}
            min={todaysDate}
            max={maxDateFormatted}
            aria-label="Charge Date"
          />
        </FormField>
      );
    }
    return null;
  };

  const PRECANCEL_PAYMENTS_BEHIND = useMemo(
    () =>
      populateTemplateString(string_table.PRECANCEL_PAYMENTS_BEHIND, {
        billingFrequency: billingFrequency.toLowerCase(),
        invoicesBehind: invoicesBehind?.toString(),
        subscriptionPrice: subscriptionPrice?.toFixed(2),
        totalBillingAmount: totalBillingAmount?.toFixed(2),
      }),
    [billingFrequency, invoicesBehind, subscriptionPrice, totalBillingAmount],
  );

  const PRECANCEL_PENDING_CANCEL = useMemo(
    () =>
      populateTemplateString(string_table.PRECANCEL_PENDING_CANCEL, {
        totalBillingAmount: totalBillingAmount?.toFixed(2),
      }),
    [totalBillingAmount],
  );

  const PRECANCEL_MEMBER_NUMBER = useMemo(
    () =>
      populateTemplateString(string_table.PRECANCEL_MEMBER_NUMBER, {
        memberNumber,
      }),
    [memberNumber],
  );

  const PRECANCEL_PRODUCT = useMemo(
    () =>
      populateTemplateString(string_table.PRECANCEL_PRODUCT, {
        friendlyProductName,
      }),
    [friendlyProductName],
  );

  const PRECANCEL_PAYMENT_METHOD = useMemo(
    () =>
      populateTemplateString(string_table.PRECANCEL_PAYMENT_METHOD, {
        lastFour,
      }),
    [lastFour],
  );

  if (isInvoicesLoading) {
    return <Spinner spinnerSize="large" blocking data-testid="spinner" />;
  }

  return (
    <Modal>
      <Container
        id="modal-content-container"
        flexbox
        classNames={['mt-4', 'flex-column']}
        data-testid="precancel-modal-container"
      >
        <Modal.Title id="modal-title" data-testid="modal-title">
          {string_table.PRECANCEL_TITLE}
        </Modal.Title>
        <Text
          id="payment-behind-text"
          text={isGroupMemberPendingCancel ? PRECANCEL_PENDING_CANCEL : PRECANCEL_PAYMENTS_BEHIND}
          data-testid="payment-behind-text"
        />
        <Container id="product-info-container" classNames={['mt-4']}>
          <Text id="product-text" text={PRECANCEL_PRODUCT} data-testid="product-text" />
          <Text id="member-number-text" text={PRECANCEL_MEMBER_NUMBER} data-testid="member-number-text" />
          <Text id="payment-method" text={PRECANCEL_PAYMENT_METHOD} data-testid="payment-method-text" />
          <ChargeDate />
        </Container>
        <Modal.Actions>
          <Container
            id="actions-container"
            flexbox
            justifyContent="center"
            alignItems="center"
            background="light-gray"
          >
            <div data-testid="tos-checkbox">
              <Checkbox
                classNames={['mb-4', 'mt-4']}
                id="checkbox-field"
                data-testid="checkbox-field"
                name="checkbox-field"
                required
                onCheckedChange={setIsChecked}
                checked={isChecked}
                rightLabel={(<RightLabel />) as unknown as string}
              />
            </div>
          </Container>
          <Button
            id="pay-renew-button"
            data-testid="pay-renew-button"
            variant="primary"
            label={string_table.PRECANCEL_PAY_RENEW}
            onClick={handlePayAndRenew}
            disabled={!isChecked || !isValidDate || isCatchupInProgress || isSubmitting}
          />
          <Button
            id="cancel-button"
            variant="secondary"
            label={string_table.PRECANCEL_CANCEL}
            onClick={() => dispatch({ type: 'HIDE_MODAL' })}
          />
        </Modal.Actions>
      </Container>
    </Modal>
  );
};

export default PrecancelModal;

