import { useEffect, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';
import { ISubscriptionResource } from '../../hooks/utils/subscription-utils';
import { usePrecancelModal } from '../../../../components/Payments/PrecancelModal/PrecancelModalContext';
import { useQueryClient } from '@tanstack/react-query';
import { useHandleUpdatePaymentMethod } from '../../../../components/Payments/hooks/useUpdatePaymentMethod';

type UsePrecancelQueryHandlerProps = {
  subscription?: ISubscriptionResource;
  isPrecancel: boolean;
  inPrecancelBiller: boolean;
};

export const usePrecancelQueryHandler = ({
  subscription,
  isPrecancel,
  inPrecancelBiller,
}: UsePrecancelQueryHandlerProps) => {
  const queryClient = useQueryClient();
  const [searchParams, setSearchParams] = useSearchParams();
  const resourceIdParam = searchParams.get('resource_id');
  const subscriptionIdParam = searchParams.get('subscriptionId');

  const { dispatch: precancelModalDispatch } = usePrecancelModal();
  const hasProcessedRef = useRef(false);

  const { mutateAsync: updatePaymentMethod } = useHandleUpdatePaymentMethod(subscription?.id);

  const clearQueryParams = () => {
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.delete('resource_id');
    newSearchParams.delete('subscriptionId');
    setSearchParams(newSearchParams);
  };

  const triggerPrecancelModal = (resourceId: string) => {
    precancelModalDispatch({
      type: 'SHOW_MODAL',
      payload: {
        subscription,
        paymentMethodId: resourceId,
      },
    });
  };

  const performImmediateUpdate = async (resourceId: string) => {
    hasProcessedRef.current = true;
    await queryClient.refetchQueries({ queryKey: ['subscription', subscription?.id] });
    await updatePaymentMethod({ paymentMethodId: resourceId });
    clearQueryParams();
    window.history.replaceState(null, '', `${location.pathname}`);
  };

  useEffect(() => {
    if (hasProcessedRef.current) return;
    if (!resourceIdParam || subscriptionIdParam !== subscription?.id) return;

    const shouldTriggerPrecancelModal = isPrecancel && !inPrecancelBiller;

    if (shouldTriggerPrecancelModal) {
      triggerPrecancelModal(resourceIdParam);
      hasProcessedRef.current = true;
      clearQueryParams();
    } else {
      performImmediateUpdate(resourceIdParam);
    }
  }, [
    resourceIdParam,
    subscription,
    subscriptionIdParam,
    isPrecancel,
    inPrecancelBiller,
    precancelModalDispatch,
    searchParams,
    setSearchParams,
  ]);
};

