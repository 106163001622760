import { getCancellableSubscriptions } from '../../CancelPlanSelector/getCancellableSubscriptions';
import { ClickToCancelConfig } from '../../../../hooks/useLaunchDarkly';
import { ISubscriptionResource } from '../../hooks/utils/subscription-utils';

export const canClickToCancel = (
  clickToCancelConfig?: ClickToCancelConfig,
  subscriptions?: ISubscriptionResource[],
) => {
  return getCancellableSubscriptions(clickToCancelConfig, subscriptions).length > 0;
};
