import React, { useCallback } from 'react';
import { Heading } from '@legalshield/adonis-ux-framework';

import UrlUtil from '../../../utils/url';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
import letterQuill from '../../../images/LetterQuill.svg';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
import documentReview from '../../../images/DocumentReview.svg';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
import memberPerks from '../../../images/MemberPerks.svg';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
import creditMonitoring from '../../../images/CreditMonitoring.svg';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
import fullServiceRestoration from '../../../images/FullServiceRestoration.svg';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
import idTheftConsultation from '../../../images/IdTheftConsultation.svg';

import { Benefit } from './Benefit';

type BenefitsListProps = {
  legalPlan: boolean;
};
export function BenefitsList({ legalPlan }: BenefitsListProps) {
  const openLink = useCallback((url) => {
    window.open(url, '_blank');
  }, []);

  return legalPlan ? (
    <div className="p-5" style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
      <Heading classNames="pb-" as="T20" text={string_table.CANCEL_PLAN_LEGAL_BENEFITS_CARD_HEADING} />
      <Benefit
        title={string_table.CANCEL_PLAN_WILL_TITLE}
        subtitle={string_table.CANCEL_PLAN_WILL_SUBTITLE}
        image={letterQuill}
        onLearnMoreClick={() => openLink(UrlUtil.getLSAppUrl('legal', '/will'))}
      />
      <Benefit
        title={string_table.CANCEL_PLAN_DOCUMENT_REVIEW_TITLE}
        subtitle={string_table.CANCEL_PLAN_DOCUMENT_REVIEW_SUBTITLE}
        image={documentReview}
        onLearnMoreClick={() => openLink(UrlUtil.getLSAppUrl('legal', '/request-consult'))}
      />
      <Benefit
        title={string_table.CANCEL_PLAN_MEMBER_PERKS_TITLE}
        subtitle={string_table.CANCEL_PLAN_MEMBER_PERKS_SUBTITLE}
        image={memberPerks}
        onLearnMoreClick={() => openLink('https://pslogin.perkspot.com/login?communityId=588')}
      />
    </div>
  ) : (
    <div className="p-5" style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
      <Heading classNames="pb-" as="T20" text={string_table.CANCEL_PLAN_IS_SHIELD_BENEFITS_CARD_HEADING} />
      <Benefit
        title={string_table.CANCEL_PLAN_CREDIT_MONITORING_TITLE}
        subtitle={string_table.CANCEL_PLAN_CREDIT_MONITORING_SUBTITLE}
        image={creditMonitoring}
        onLearnMoreClick={() => openLink(UrlUtil.getLSAppUrl('ids', '/credit'))}
      />
      <Benefit
        title={string_table.CANCEL_PLAN_FULL_SERIVCE_RESTORATION_TITLE}
        subtitle={string_table.CANCEL_PLAN_FULL_SERIVCE_RESTORATION_SUBTITLE}
        image={fullServiceRestoration}
        onLearnMoreClick={() => openLink(UrlUtil.getLSAppUrl('ids', '/account/consultation-and-restoration'))}
      />
      <Benefit
        title={string_table.CANCEL_PLAN_ID_THEFT_CONSULT_TITLE}
        subtitle={string_table.CANCEL_PLAN_ID_THEFT_CONSULT_SUBTITLE}
        image={idTheftConsultation}
        onLearnMoreClick={() => openLink(UrlUtil.getLSAppUrl('ids', '/account/features'))}
      />
    </div>
  );
}
