import React, { FC, useEffect, useState } from 'react';

import { Container, Text, Button, Spinner } from '@legalshield/adonis-ux-framework';
import { Navigate, useLocation, useNavigate, useParams } from 'react-router-dom';
import Cookies from 'js-cookie';

import AvailablePlan from '../../AvailablePlan/AvailablePlan';
import ConfirmUpgradeModal from '../ConfirmUpgradeModal/ConfirmUpgradeModal';
import { Layout } from '../../Layout/Layout';
import { UpgradePlanSkeleton } from '../../UpgradePlanSkeleton/AvailablePlanSkeleton';
import { getFriendlyProductNameFromSubscription } from '../../../utils/getFriendlyName';
import { useGetSubscriptionBySubscriptionId } from '../hooks/useSubscription';
import { useGetProfile } from '../../../hooks/useProfile';
import { AvailableMerchandizedTier, useUpgradeableSubscriptions } from '../utils/getUpgradeableSubscriptions';
import { formatExactDate } from '../utils/utils';
import GtmService from '../../../services/gtm.service';
import { useUpdateSubscriptionTier } from '../utils/updateSubscriptionTier';
import { populateTemplateString } from '../../../utils/stringFormat';

import './UpgradePlan.scss';

const UpgradePlan: FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const [showUpgradeModal, setShowUpgradeModal] = useState(false);
  const [selectedPlanData, setSelectedPlanData] = useState<AvailableMerchandizedTier | null>(null);

  const { data: upgradesData, isLoading: upgradeableSubscriptionLoading } = useUpgradeableSubscriptions();

  const upgrade = upgradesData?.find((upgrade) => upgrade.subscription.id === id);
  const merchandisedTiers = upgrade?.availableMerchandizedTiers;

  const { data: subscription, isLoading: subscriptionsLoading } = useGetSubscriptionBySubscriptionId(id);
  const { data: profile, isLoading: profileLoading } = useGetProfile();

  const currentPlanName = subscription?.offer.offerItems.filter((offerItem) => offerItem.type === 'PRODUCT')[0]
    .productDetail.productTier;

  const upgradeSubtitle = populateTemplateString(string_table.UPGRADE_PLAN_PAGE_SUBTITLE, { currentPlanName });

  // Use the custom hook, passing the current subscription as an argument
  const { mutate, isError, isSuccess, isPending } = useUpdateSubscriptionTier(subscription);

  useEffect(() => {
    if (isSuccess) {
      const userId = pplsi?.authNPayload?.sub || '';
      Cookies.set(`upgradeSuccessful-${userId}`, 'true', { expires: 3 });
    }
  }, [isSuccess]);

  function handleUpgradeConfirm() {
    GtmService.beginUpgradeGtmEvent();
    mutate({ selectedTier: selectedPlanData, profile: profile });
  }

  const modalState = isError ? 'error' : isSuccess ? 'success' : 'confirm';

  if (subscriptionsLoading || profileLoading) return <Spinner blocking spinnerSize="xlarge" />;
  if (!subscription && !subscriptionsLoading) {
    return <Navigate to="/payments" replace />;
  }

  const crumbs = [
    { label: string_table.OVERVIEW_TITLE, link: '/overview' },
    { label: string_table.PAYMENT_TITLE, link: '/payments' },
    { label: getFriendlyProductNameFromSubscription(subscription) || 'Subscription' },
    { label: string_table.UPGRADE_PLAN_TITLE, link: '/payments/:id/upgrade' },
  ];

  function handleUpgradeClick(tier: AvailableMerchandizedTier) {
    setSelectedPlanData(tier);
    setShowUpgradeModal(true);
    GtmService.viewBeginUpgradeGtmEvent();
  }

  function handleUpgradeCancel() {
    setShowUpgradeModal(false);
    setSelectedPlanData(null);
  }

  function handleUpgradeDone() {
    setShowUpgradeModal(false);
    setSelectedPlanData(null);
    GtmService.viewUpgradeConfirmationGtmEvent();
    navigate('/overview', {
      state: {
        fromUpgrade: true,
        fromOverview: location.state?.fromOverview,
      },
    });
  }

  function handleLikePress() {
    setShowUpgradeModal(false);
    setSelectedPlanData(null);
    GtmService.viewUpgradeConfirmationGtmEvent();
    GtmService.upgradeThumbsUpGtmEvent();
    navigate('/overview', {
      state: {
        fromUpgrade: true,
        fromOverview: location.state?.fromOverview,
      },
    });
  }

  function handleDislikePress() {
    setShowUpgradeModal(false);
    setSelectedPlanData(null);
    GtmService.viewUpgradeConfirmationGtmEvent();
    GtmService.upgradeThumbsDownGtmEvent();
    navigate('/overview', {
      state: {
        fromUpgrade: true,
        fromOverview: location.state?.fromOverview,
      },
    });
  }

  function handleUpgradeErrorOk() {
    setShowUpgradeModal(false);
    setSelectedPlanData(null);
  }

  return (
    <Layout crumbs={crumbs} title={string_table.UPGRADE_PLAN_PAGE_TITLE} subtitle={upgradeSubtitle}>
      {showUpgradeModal && (
        <ConfirmUpgradeModal
          modalState={modalState}
          open={showUpgradeModal}
          onOpenChange={setShowUpgradeModal}
          closeButton={true}
          title={string_table.UPGRADE_CONFIRMATION_TITLE}
          plan={selectedPlanData.tier}
          monthlyPrice={selectedPlanData.price.renewalListPrice.toString()}
          memberNumber={subscription.source.friendlyId}
          loadingTitle={`${string_table.UPGRADE_CONFIRMATION_LOADING_TEXT}...`}
          isLoading={isPending}
          onCancelPress={handleUpgradeCancel}
          onConfirmPress={handleUpgradeConfirm}
          onDonePress={handleUpgradeDone}
          onLikePress={handleLikePress}
          onDislikePress={handleDislikePress}
          onErrorOkPress={handleUpgradeErrorOk}
          nextBillOn={formatExactDate(subscription?.nextBillOn)}
          tier={selectedPlanData.tier}
          subscription={subscription}
        />
      )}
      <Container background="none" flexDirection="column" flexbox justifyContent="center" classNames={['mt-6']}>
        {upgradeableSubscriptionLoading ? (
          <UpgradePlanSkeleton />
        ) : (
          <>
            <Container background="none" flexbox flexDirection="column" classNames={['mb-5']}>
              <Container background="none" flexbox flexDirection="row">
                <Text textSize="large" text={'Current Plan:'} textWeight="semibold" />
                <Text
                  textSize="large"
                  text={getFriendlyProductNameFromSubscription(subscription)}
                  classNames={['ml-2']}
                />
              </Container>
              <Container background="none" flexbox flexDirection="row">
                <Text textSize="large" text={'Member Number:'} textWeight="semibold" />
                <Text textSize="large" text={subscription.source.friendlyId} classNames={['ml-2']} />
              </Container>
            </Container>
            <Container background="none" classNames={['mt-5', 'upgrade-plan__desktop', 'upgrade-plan__mobile']}>
              {merchandisedTiers?.map((tier, index) =>
                tier.availableForSale ? (
                  <div key={`tier-option-${index}`}>
                    <AvailablePlan
                      classNames={['upgrade-plan__available_plan']}
                      tier={tier}
                      subscription={subscription}
                      buttonOnClick={() => handleUpgradeClick(tier as AvailableMerchandizedTier)}
                    />
                  </div>
                ) : null,
              )}
            </Container>
          </>
        )}
        <Container background="none" flexbox flexDirection="column" classNames={['upgrade-plan__footer']}>
          {/* <Button
            classNames={['upgrade-plan__compare_plans_button', 'compare_plans__mobile']}
            variant="primary"
            label={string_table.COMPARE_PLANS}
            stretch
          /> */}
          <Text text={string_table.UPGRADE_PLAN_FOOTER_TEXT} textSize="small" classNames={['mt-6']} />
          <Button
            classNames={['upgrade-plan__subscription_button', 'subscription_button__mobile']}
            variant="secondary"
            label={string_table.KEEP_SUBSCRIPTION}
            stretch
            onClick={() => navigate('/payments')}
          />
          <p className="mt-5 upgrade-plan__footer_text">
            {string_table.UPGRADE_PAGE_FOOTER_TEXT}
            <span className="upgrade-plan__footer_number"> (844) 714-8494</span>
          </p>
        </Container>
      </Container>
    </Layout>
  );
};

export default UpgradePlan;
