import React, { FC } from 'react';
import { Container, Link } from '@legalshield/adonis-ux-framework';

export const SubscriptionCancelLink: FC = () => (
  <Container flexbox justifyContent={'center'} classNames={['mt-6', 'mb-4']}>
    <Link
      text={string_table.CANCEL_PLAN_LINK_TEXT}
      textWeight="semibold"
      href="/payments/cancel"
      style={{ color: 'var(--p200)' }}
    />
  </Container>
);
