/* eslint-disable sort-keys */
import React, { FC, Fragment, useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Navigation, NavigationItem, useViewportSize } from '@legalshield/adonis-ux-framework';
import { Realm } from '@legalshield/frontend-commons/dist/sdk/identities';

import RealmUtility from '../../utils/realm';
import { useLaunchDarkly } from '../../hooks/useLaunchDarkly';
import Config from '../../config';
import URLs from '../../utils/url';
import { useGetEntitlements } from '../../hooks/useEntitlements';
import { useGetProfile } from '../../hooks/useProfile';
import { hasActiveLSFamilyPlan } from '../../utils/activeFamilyPlan';

const getLegalUrl = () => {
  return URLs.getLSAppUrl(Config.currentEnv.apps.legal, '/members/');
};
const manageMembersUrl = getLegalUrl();

export interface NavButton extends NavigationItem {
  accessLinkURL?: string;
  name: string;
  iconName?: string;
  idsLinkText?: string;
  idsLinkURL?: string;
  imageAlt?: string;
  imageSrc?: string;
  legalLinkText?: string;
  legalLinkURL?: string;
  linkText?: string;
  to?: string;
  data?: string;
  target?: string;
  text?: string;
}

const overview: NavButton = {
  data: '/overview',
  iconName: 'menu_more_grid_big',
  name: 'Overview',
  target: '_self',
};

const profile: NavButton = {
  data: '/profile',
  iconName: 'nav_profile',
  name: 'Profile',
  target: '_self',
  text: string_table.SETTINGS_PROFILE_DESCRIPTION,
};

const payments: NavButton = {
  data: '/payments',
  iconName: 'interface_credit_card_01',
  name: 'Billing & Plans',
  target: '_self',
  text: string_table.ADD_OR_EDIT_PAYMENT_METHOD,
};

const security: NavButton = {
  data: '/security',
  iconName: 'interface_lock',
  name: 'Security',
  target: '_self',
  text: string_table.SETTINGS_SECURITY_DESCRIPTION,
};

const resources: NavButton = {
  data: '/resources',
  iconName: 'interface_book',
  name: 'Resources',
  target: '_self',
  text: string_table.SETTINGS_RESOURCES_DESCRIPTION,
};

const notifications: NavButton = {
  data: '/notifications',
  iconName: 'comm_bell',
  name: 'Notifications',
  target: '_self',
  text: string_table.SETTINGS_NOTIFICATIONS_DESCRIPTION,
};

const family: NavButton = {
  data: manageMembersUrl,
  iconName: 'user_users',
  name: string_table.FAMILY,
  target: '_blank',
  text: string_table.SETTINGS_FAMILY_DESCRIPTION,
};

const faq: NavButton = {
  data: '/faq',
  iconName: 'nav_help',
  name: 'FAQ',
  target: '_self',
  text: 'Frequently asked questions about our products',
};

export const defaultNavItems: NavButton[] = [overview, profile, security];
export const userNavItemsWithFamilyLink: NavButton[] = [
  overview,
  profile,
  payments,
  security,
  family,
  resources,
  notifications,
  faq,
];
export const userNavItems: NavButton[] = [overview, profile, payments, security, resources, notifications, faq];

export const defaultNavButtons: NavButton[] = [profile, security];

export const userNavButtons: NavButton[] = [profile, payments, security, resources, notifications, faq];
export const userNavButtonsWithFamilyLink: NavButton[] = [
  family,
  profile,
  payments,
  security,
  resources,
  notifications,
  faq,
];

export const NavigationBar: FC = () => {
  const { familyManagementLink, referralProgram } = useLaunchDarkly();
  const { data: entitlements } = useGetEntitlements();

  const productType = 'Legal Plan Family';
  const { data: profile } = useGetProfile();

  const hasOtherActiveProductType =
    entitlements && entitlements.some((e) => e.product?.productType !== productType && e.status === 'ACTIVE');

  const [navigationOpen, setNavigationOpen] = useState(false);
  const [activeData, setActiveData] = useState('');
  const [navItems, setNavItems] = useState<NavigationItem[]>([]);
  const location = useLocation();
  const navigate = useNavigate();

  const realm = RealmUtility.getRealm();

  const { width } = useViewportSize();
  const breakpoint = 1023;

  const openClicked = () => setNavigationOpen(true);
  const closeClicked = () => setNavigationOpen(false);

  const noSidebarPaths = ['/setup', '/page-not-found'];

  if (noSidebarPaths.includes('/overview')) noSidebarPaths.splice(noSidebarPaths.indexOf('/overview'), 1);
  if (noSidebarPaths.includes('/overview/profile'))
    noSidebarPaths.splice(noSidebarPaths.indexOf('/overview/profile'), 1);
  if (noSidebarPaths.includes('/overview/notifications'))
    noSidebarPaths.splice(noSidebarPaths.indexOf('/overview/notifications'), 1);
  if (!window.location.pathname.includes('/setup')) {
    ads_set_hamburger_callback(openClicked);
  }

  useEffect(() => {
    const userId = pplsi?.authNPayload?.sub || '';
    const referNavItem = {
      data: referralProgram?.url,
      iconName: 'interface_gift',
      name: string_table.REFER_FRIENDS_NAV_TITLE,
    };

    if (userId !== '' && location.pathname !== '/') {
      const navItemsWithoutReferral = realm === Realm.User ? userNavItems : defaultNavItems;

      const navItemsWithReferral = [...navItemsWithoutReferral, referNavItem];

      const navItems = referralProgram?.url ? navItemsWithReferral : navItemsWithoutReferral;
      setNavItems([...navItems]);
    }
    const navItemsWithoutFamilyLink = realm === Realm.User ? userNavItems : defaultNavItems;

    const navItemsWithFamilyLink = userNavItemsWithFamilyLink;

    const familyNavItems =
      familyManagementLink &&
      !hasOtherActiveProductType &&
      hasActiveLSFamilyPlan(entitlements, productType, profile) === true
        ? navItemsWithFamilyLink
        : navItemsWithoutFamilyLink;

    const newNavItems = referralProgram?.url ? [...familyNavItems, referNavItem] : familyNavItems;
    setNavItems([...newNavItems]);
  }, [location.pathname, referralProgram, familyManagementLink, entitlements, productType, profile]);

  const handleButtonClick = (_: number, data: string) => {
    if (data.includes('https')) {
      window.open(data, '_blank');
      return;
    }

    navigate(data);
    setNavigationOpen(false);
  };

  useEffect(() => {
    if (location.pathname === '/') {
      if (pplsi.authNPayload?.sub_type !== 'Partner') {
        setActiveData('/products');
      }
      if (pplsi.authNPayload?.sub_type === 'Partner') {
        setActiveData('/profile');
      }
    } else if (location.pathname.includes('/payments')) {
      setActiveData('/payments');
    } else {
      setActiveData(location.pathname);
    }
  }, [location, setActiveData]);

  // If there are no navigation items, do not render the component
  if (!navItems) return <Fragment />;

  // If the current path is in the noSidebarPaths array, do not render the component
  if (!noSidebarPaths.every((path) => !window.location.pathname.includes(path))) return <Fragment />;

  return (
    <Navigation
      activeData={activeData}
      isOpen={navigationOpen}
      items={navItems}
      classNames={['lsux-navigation', 'main-nav']}
      navigationType={width > breakpoint ? 'pane' : 'slideout'}
      onClick={handleButtonClick}
      onClose={closeClicked}
    />
  );
};
