import React, { FC } from 'react';
import { Container, GridCol, Heading, Icon, Image, Text } from '@legalshield/adonis-ux-framework';
import CardButton from '../CardButton/CardButton';
import { NavButton } from '../../NavigationBar/NavigationBar';

interface QuickActionsCardProps extends NavButton {
  iconName?: string;
  imageAlt?: string;
  imageSrc?: string;
  name: string;
  target?: string;
  text?: string;
  to?: string;
}

const QuickActionsCard: FC<QuickActionsCardProps> = ({ iconName, imageAlt, imageSrc, name, target, text, to }) => (
  <GridCol>
    <CardButton heading={name} iconName={iconName} showArrow={true} target={target} text={text} to={to}>
      <Container
        alignContent="center"
        alignItems="center"
        background="none"
        classNames={['p-5']}
        flexbox
        flexDirection="row"
      >
        <Image
          alt={imageAlt}
          src={imageSrc}
          style={{
            width: '32px',
            height: '32px',
          }}
        />
        <Heading as="T14" classNames={['ml-3']} text={name} />
        {text && (
          <Text textSize="large" textWeight="semibold">
            {text}
          </Text>
        )}
      </Container>
    </CardButton>
  </GridCol>
);

export default QuickActionsCard;
